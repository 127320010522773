import React, { useState } from 'react';
import { Tab, styled, Box } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import StockOfferAttributes from './StockOfferAttributes';
import StockOfferCombinations from './StockOfferCombinations';


const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: 0
}));


export default function StockOffersModalForm({ uniqueOfferID, handleCancel, handleConfirm, confirmCB }) {

    const [currentTab, setCurrentTab] = useState("attributes");


    const onTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <div style={{ maxHeight: "80vh" }}>
            <TabContext value={currentTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        onChange={onTabChange}
                        variant="scrollable"
                        scrollButtons={true}
                    >
                        <Tab label="Attributes" value="attributes" />
                        <Tab label="Combinations" value="combinations" />
                    </TabList>
                </Box>
                <StyledTabPanel value="attributes">
                    <StockOfferAttributes uniqueOfferID={uniqueOfferID} />
                </StyledTabPanel>
                <StyledTabPanel value="combinations">
                    <StockOfferCombinations uniqueOfferID={uniqueOfferID} />
                </StyledTabPanel>
            </TabContext>
        </div>
    );
}
