import Backend from 'Backend';


export const apiGetReviews = async (filter) => {

    const queryString = new URLSearchParams(filter).toString();

    const res = await fetch(`${Backend.backendURLs.offerReviewsURL}?${queryString}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }
    
    return await res.json();

}

export const apiUpdateReviewStatus = async ({reviewID, status, remove}) => {

    const res = await fetch(Backend.backendURLs.offerReviewsURL, {
        method: Backend.backendMethods.put,
        headers: Backend.generateHeader(),
        body: JSON.stringify({reviewID, status, delete: remove}),
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }
    
    return await res.json();

}


export const apiGetReviewsTopics = async (filter) => {

    const queryString = new URLSearchParams(filter).toString();

    const res = await fetch(`${Backend.backendURLs.offerReviewsTopicsURL}?${queryString}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }
    
    return await res.json();

}


export const apiDeleteReviewTopic = async (props) => {

    const res = await fetch(Backend.backendURLs.offerReviewsTopicsURL, {
        method: Backend.backendMethods.put,
        headers: Backend.generateHeader(),
        body: JSON.stringify(props),
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }
    
    return await res.json();

}