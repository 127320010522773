import React from 'react';
import EditIcon from '@mui/icons-material/Edit';

const OfferMultiStep = ({ step, handlePropsChange }) => {
  return (
    <div className='modal-multi-offer-step'>
      <div className='modal-multi-offer-step-number'>
        <p>{step['stepNumber']}</p>
      </div>
      <div className='modal-multi-offer-step-input-wrapper'>
        <textarea
          className='modal-multi-offer-step-input'
          id="standard-multiline-flexible"
          defaultValue={step['stepDescription']}
          type='text'
          onChange={(e) => {
            handlePropsChange(prev => {
              const result = prev.map(item => {
                if (item['stepNumber'] === step['stepNumber']) {
                  step['stepDescription'] = e.target.value;
                }
                return item;
              });
              return result;
            });
          }}
        />
        <EditIcon />
      </div>
      <div className='modal-multi-offer-step-input-wrapper'>
        <input
          className='modal-multi-offer-step-input'
          id="standard-multiline-flexible"
          defaultValue={`${step['stepCoins']}`}
          type='number'
          onChange={(e) => {
            handlePropsChange(prev => {
              const result = prev.map(item => {
                if (item['stepNumber'] === step['stepNumber']) {
                  step['stepCoins'] = Number(e.target.value);
                }
                return item;
              });
              return result;
            });
          }}
        />
        <EditIcon />
      </div>
    </div>
  );
};

export default OfferMultiStep;