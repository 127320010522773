import React, { useState } from "react";
import { Grid } from "@mui/material";
import MultiSelectAll from "./MultiSelectAll";
import { COUNTRIES } from "../../constrants/countries";


const MultiSelectWithSearch = ({selected, onSelectChange}) => {

    const [currentSelection, setCurrentSelection] = useState(selected);

    return (
        <Grid container flexDirection="row" alignItems="center">
            <Grid item xs={12} sx={{ pb: 2 }}>
                <MultiSelectAll
                    items={COUNTRIES}
                    selectAllLabel="Select All"
                    value={currentSelection}
                    onChange={onSelectChange}
                />
            </Grid>
        </Grid>
    );

}

export default MultiSelectWithSearch