import Backend from 'Backend';

export const apiGetProviders = async (filter) => {

    const queryString = new URLSearchParams(filter).toString();

    const res = await fetch(`${Backend.backendURLs.getProvidersURL}?${queryString}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }
    
    return await res.json();

}


export const apiAddProvider = async (providerName) => {

    const res = await fetch(Backend.backendURLs.addNewProviderUrl, {
        method: Backend.backendMethods.post,
        body: JSON.stringify({
            providerName
        }),
        headers: Backend.generateHeader()
    })

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}