import React, { forwardRef} from 'react';
import { Box, InputLabel, FormControl, Select, MenuItem } from '@mui/material';


const SearchBySelect = forwardRef( (props, ref) => {

    const { 
        data, 
        name, 
        label,
        defaultValue,
        onChange
    } = props;


    const fieldName = name ?? "searchBy"
    const fieldLabel = label ?? "Search By"

    const handleChange = e => {
        if(onChange){
            onChange(e)
        }
    }

    return (
        <Box>
            <FormControl size="small" sx={{ minWidth: 150, textAlign: 'left' }}>
                <InputLabel>{fieldLabel}</InputLabel>
                <Select
                    size="small"
                    inputRef={ref}
                    label={fieldLabel}
                    defaultValue={defaultValue || ""}
                    name={fieldName}
                    onChange={handleChange}
                >
                    {Object.keys(data).map((item, idx) => (
                        <MenuItem value={data[item].id} key={idx}>
                            {data[item].label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )

})

export default SearchBySelect;