import Backend from 'Backend.js';
import React from 'react';
import Alerts from 'utils/Alerts.js';

const content = require('content.json');

const backendURLs = Backend.backendURLs;
const updateSubscriptionSettingsURL = backendURLs.updateSubscriptionSettingsURL;

function ManageSubcriptionsModalForm({ handleConfirm, data, changeHandler }) {
    const handleSubmit = () => {
        handleConfirm();

        const postBody = data;
        fetch(updateSubscriptionSettingsURL, {
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify(postBody)
        })
            .then(async function (res) {
                if (res.status !== Backend.backendResponseCodes.internalServerErrorCode &&
                    res.status !== Backend.backendResponseCodes.serviceUnavailableCode) {
                    let result = await res.json();

                    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
                        let result = await res.json();

                        Backend.logOut();
                    }
                    if (res.status === Backend.backendResponseCodes.OKCode) {
                        Alerts.showSuccessAlert(content.subscription_settings_updated);
                    }
                    else {
                        Alerts.showErrorAlert(result.message);
                    }
                }
                else {
                    Alerts.showErrorAlert(Backend.backendMessages.errorMessage);
                }
            });
    };

    return (
        <div className='select-container'>
            <>
                <div className='margin-bottom-10'>
                    <h2 className='close-modal-description font-20 text-left'>{content.subscription_settings_email}</h2>
                    <div>
                        <label className="checkbox-container font subscription-settings-container">
                            <span className="notification-setting-label">{content.receive_notifications_reminders}</span>
                            <input
                                type="checkbox"
                                name="receiveNotificationsEmail"
                                className="subscription-settings-checkbox"
                                checked={data?.receiveNotificationsEmail}
                                onChange={(evt) => changeHandler(({ ...data, receiveNotificationsEmail: evt.target.checked }))}
                            />
                            <span className="checkmark-checkbox left-checkbox"></span>
                        </label>
                    </div>

                    <div>
                        <label className="checkbox-container font subscription-settings-container">
                            <span className="notification-setting-label">{content.receive_referral_registration_notifications}</span>
                            <input
                                type="checkbox"
                                name="receiveReferralRegistrationEmail"
                                className="subscription-settings-checkbox"
                                checked={data?.receiveReferralRegistrationEmail}
                                onChange={(evt) => changeHandler(({ ...data, receiveReferralRegistrationEmail: evt.target.checked }))}
                            />
                            <span className="checkmark-checkbox left-checkbox"></span>
                        </label>
                    </div>
                </div>
                <div>
                    <h2 className='close-modal-description font-20 text-left'>{content.subscription_settings_telegram}</h2>
                    <div>
                        <label className="checkbox-container font subscription-settings-container">
                            <span className="notification-setting-label">{content.receive_notifications_reminders}</span>
                            <input
                                type="checkbox"
                                name="receiveNotificationsTelegram"
                                className="subscription-settings-checkbox"
                                checked={data?.receiveNotificationsTelegram}
                                onChange={(evt) => changeHandler(({ ...data, receiveNotificationsTelegram: evt.target.checked }))}
                            />
                            <span className="checkmark-checkbox left-checkbox"></span>
                        </label>
                    </div>
                    <div>
                        <label className="checkbox-container font subscription-settings-container">
                            <span className="notification-setting-label">{content.receive_offer_completion_notifications}</span>
                            <input
                                type="checkbox"
                                name="receiveOfferCompletionTelegram"
                                className="subscription-settings-checkbox"
                                checked={data?.receiveOfferCompletionTelegram}
                                onChange={(evt) => changeHandler(({ ...data, receiveOfferCompletionTelegram: evt.target.checked }))}
                            />
                            <span className="checkmark-checkbox left-checkbox"></span>
                        </label>
                    </div>
                    <div>
                        <label className="checkbox-container font subscription-settings-container">
                            <span className="notification-setting-label">{content.receive_referral_offer_completion_notifications}</span>
                            <input
                                type="checkbox"
                                name="receiveReferralOfferCompletionTelegram"
                                className="subscription-settings-checkbox"
                                checked={data?.receiveReferralOfferCompletionTelegram}
                                onChange={(evt) => changeHandler(({ ...data, receiveReferralOfferCompletionTelegram: evt.target.checked }))}
                            />
                            <span className="checkmark-checkbox left-checkbox"></span>
                        </label>
                    </div>
                    <div>
                        <label className="checkbox-container font subscription-settings-container">
                            <span className="notification-setting-label">{content.receive_referral_registration_notifications}</span>
                            <input
                                type="checkbox"
                                name="receiveReferralRegistrationTelegram"
                                className="subscription-settings-checkbox"
                                checked={data?.receiveReferralRegistrationTelegram}
                                onChange={(evt) => changeHandler(({ ...data, receiveReferralRegistrationTelegram: evt.target.checked }))}
                            />
                            <span className="checkmark-checkbox left-checkbox"></span>
                        </label>
                    </div>
                </div>
            </>

            <hr className='give-bonus-swal-hr' />
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled manage-subscriptions-modal-confirm-button margin-top-10'>{content.update_subscription_settings}</button>
        </div>
    );
}

export default ManageSubcriptionsModalForm;