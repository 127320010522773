import React, { useEffect, useState, useRef } from 'react';
import { NativeSelect, Alert, TextField, Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { apiGetOfferStockCombinations, apiSaveOfferCombination, apiSyncOfferCombination } from '../../services/OfferService';
import LoadingButton from '@mui/lab/LoadingButton';
import SyncIcon from '@mui/icons-material/Sync';
import BootstrapInput from '../../styles/BootstrapInput';
import { TableVirtuoso } from 'react-virtuoso';


const columns = [
    {
        width: 150,
        label: 'Combination',
        dataKey: 'AttributesValues',
    },
    {
        width: 120,
        label: 'TC Payout',
        dataKey: 'Coins',
    },
    {
        width: 120,
        label: 'Clickthrough Cap',
        dataKey: 'ClickthroughCap',
    },
    {
        width: 120,
        label: 'Clickthrough Cap Duration',
        dataKey: 'ClickthroughCapDuration',
    },
    {
        width: 100,
        label: 'Clickthrough Left',
        dataKey: 'ClickthroughLeft',
    },
    {
        width: 120,
        label: 'Completions Cap',
        dataKey: 'CompletionsCap',
    },
    {
        width: 120,
        label: 'Completions Cap Duration',
        dataKey: 'CompletionsCapDuration',
    },
    {
        width: 100,
        label: 'Completions Left',
        dataKey: 'protein',
    },
    {
        width: 110,
        label: '',
    },
];


const StockOfferCombinations = (props) => {

    const { uniqueOfferID } = props;

    const [combinations, setCombinations] = useState([]);
    const [isSavingCombination, setIsSavingCombination] = useState({});
    const [saveCombMessage, setSaveCombMessage] = useState({});

    const tcPayoutRef = useRef([]);
    const clickthroughCapRef = useRef([]);
    const clickthroughCapDurationRef = useRef([]);
    const completionsCapRef = useRef([]);
    const completionsCapDurationRef = useRef([]);


    useEffect(() => {
        getOfferStockCombinations()
    }, [])


    const getOfferStockCombinations = async () => {
        const offerStockCombinations = await apiGetOfferStockCombinations(uniqueOfferID)
        setCombinations(offerStockCombinations.stockCombinations)
    }


    const syncCombinations = async () => {
        setSaveCombMessage({})
        const res = await apiSyncOfferCombination(uniqueOfferID)
        setSaveCombMessage({ 
            msg: res?.status == "success" ? "Offer combinations synces successfully" : "Offer combinations sync failed", 
            type: res?.status == "success" ? "success" : "error"
        })
    }
    

    const saveCombination = async (idx) => {
        setSaveCombMessage({})
        let newCombs = [...combinations];
        const combinationsObj = {
            Coins: tcPayoutRef.current[idx].value,
            ClickthroughCap: clickthroughCapRef.current[idx].value,
            ClickthroughCapDuration: clickthroughCapDurationRef.current[idx].value,
            CompletionsCap: completionsCapRef.current[idx].value,
            CompletionsCapDuration: completionsCapDurationRef.current[idx].value
        };
        newCombs[idx] = {
            ...newCombs[idx],
            ...combinationsObj
        };
        setCombinations(newCombs);
        
        setIsSavingCombination(prev => ({ ...prev, [idx]: true }));
        const res = await apiSaveOfferCombination(uniqueOfferID, newCombs[idx].ID, combinationsObj);
        setIsSavingCombination(prev => ({ ...prev, [idx]: false }));
        setSaveCombMessage({ msg: res.message, type: res?.status == "success" ? "success" : "error"})

    }

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table size="small" {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead: React.forwardRef((props, ref) => <TableHead sx={{ background: '#fff' }} {...props} ref={ref} />),
        TableRow,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    const fixedHeaderContent = () => {
        return (
            <TableRow>
                {columns.map((column, idx) => (
                    <TableCell
                        key={idx}
                        variant="head"
                        align="left"
                        style={{ width: column.width }}
                        sx={{
                            fontWeight: 'bold',
                            pl: 1,
                            pr: 1
                        }}
                    >
                        {column.label}
                    </TableCell>
                ))}
            </TableRow>
        );
    }

    const rowContent = (idx, row) => {
        return (
            <>
                <TableCell sx={{ pr: 1, pl: 1 }} component="th" scope="row">
                    {row.AttributesValues}
                </TableCell>
                <TableCell sx={{ pr: 1, pl: 1 }}>
                    <TextField
                        inputProps={{ type: 'number'}}
                        key={idx}
                        size="small"
                        className="grid-list_flex-form_input"
                        defaultValue={row.Coins}
                        inputRef={el => tcPayoutRef.current[idx] = el}
                    />
                </TableCell>
                <TableCell sx={{ pr: 1, pl: 1 }}>
                    <TextField
                        inputProps={{ type: 'number'}}
                        key={idx}
                        size="small"
                        className="grid-list_flex-form_input"
                        defaultValue={row.ClickthroughCap}
                        inputRef={el => clickthroughCapRef.current[idx] = el}
                    />
                </TableCell>
                <TableCell sx={{ pr: 1, pl: 1 }}>
                    <NativeSelect
                        key={idx}
                        size="small"
                        defaultValue={row.ClickthroughCapDuration}
                        inputProps={{ 'aria-label': 'Without label' }}
                        fullWidth
                        inputRef={el => clickthroughCapDurationRef.current[idx] = el}
                        input={<BootstrapInput />}
                    >
                        <option aria-label="N/A" value={0}>N/A</option >
                        <option value={1}>Daily</option >
                        <option value={2}>Monthly</option >
                        <option value={3}>Total</option >
                    </NativeSelect>
                </TableCell>
                <TableCell sx={{ pr: 1, pl: 1 }}>{row.ClickthroughLeft}</TableCell>
                <TableCell sx={{ pr: 1, pl: 1 }}>
                    <TextField
                        inputProps={{ type: 'number'}}
                        key={idx}
                        size="small"
                        className="grid-list_flex-form_input"
                        defaultValue={row.CompletionsCap}
                        inputRef={el => completionsCapRef.current[idx] = el}
                    />
                </TableCell>
                <TableCell sx={{ pr: 1, pl: 1 }}>
                    <NativeSelect
                        size="small"
                        defaultValue={row.CompletionsCapDuration}
                        inputRef={el => completionsCapDurationRef.current[idx] = el}
                        inputProps={{ 'aria-label': 'Without label' }}
                        fullWidth
                        input={<BootstrapInput />}
                    >
                        <option aria-label="N/A" value={0}>N/A</option >
                        <option value={1}>Daily</option >
                        <option value={2}>Monthly</option >
                        <option value={3}>Total</option >
                    </NativeSelect>
                </TableCell>
                <TableCell sx={{ pr: 1, pl: 1 }}>{row.CompletionsLeft}</TableCell>
                <TableCell sx={{ pr: 1, pl: 1 }}>
                    <LoadingButton
                        size="small"
                        loading={isSavingCombination?.[idx]}
                        onClick={() => saveCombination(idx)}
                        color="primary"
                    >
                        <span>Save</span>
                    </LoadingButton>
                </TableCell>

            </>
        );
    }

    return (
        <>
            {saveCombMessage.msg && (
                <Alert severity={saveCombMessage.type}>{saveCombMessage.msg}</Alert>
            )}
            <Paper style={{ height: "calc(80vh - 160px)", marginBottom: 20, width: '100%' }}>
                <TableVirtuoso
                    data={combinations}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={fixedHeaderContent}
                    itemContent={rowContent}
                />
            </Paper>
            <Button
                onClick={() => syncCombinations()}
                color="primary"
                variant="contained"
                startIcon={<SyncIcon />}
            >
                Sync and publish to users
            </Button>
        </>
    );
}

export default StockOfferCombinations