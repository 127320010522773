import React from 'react';

const content = require('content.json');

export default function AddFreeOfferModalFormFirstSection({
    info,
    infoChangeHandler,
}) {
    const inputChangeHandler = (key, isBoolean) => evt => {
        const { target: { value, checked } } = evt;

        const newValue = isBoolean ? checked : value;
        infoChangeHandler(key, newValue);
    }

    return (
        <div className="free-offer-modal-first">
            <div className="text-right margin-bottom-20">
                <div className="flex gap-30 items-stretch margin-top-20">
                    <div className="flex-50">
                        <div className='text-left'>
                            <h2 className='text-left comment-ticket-popup-description margin-bottom-10'>{ content.min_balance_usd }</h2>
                            <input 
                                type='number' 
                                className="font w-full"
                                min={0.00}
                                step={"0.01"}
                                value={info.minBalance}
                                onChange={inputChangeHandler("minBalance")}
                            />
                        </div>
                    </div>
                    <div className="flex-50">
                        <div className='text-left'>
                            <h2 className='text-left comment-ticket-popup-description margin-bottom-10'>{ content.max_balance_usd }</h2>
                            <input 
                                type='number' 
                                className="font w-full"
                                min={0.00}
                                step={"0.01"}
                                value={info.maxBalance}
                                onChange={inputChangeHandler("maxBalance")}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex gap-30 items-stretch margin-top-20">
                    <div className="flex-50">
                        <div className='text-left flex items-center gap-10'>
                            <h2 className='text-left comment-ticket-popup-description'>{ content.clicks_to_close_offer }</h2>
                            <input 
                                type='number' 
                                className="font w-100-input"
                                min={0.00}
                                value={info.clicks}
                                onChange={inputChangeHandler("clicks")}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}