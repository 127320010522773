import React, { useEffect, useState } from 'react';
import Alerts from 'utils/Alerts.js';
import Backend from 'Backend.js';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';

const content = require('content.json');

const backendURLs = Backend.backendURLs;
const getDataURL = backendURLs.getHashtagsURL;
const loadURL = backendURLs.offerUpdateUrl;

export default function OfferHashtagsModalForm({ offerID, value, handleCancel, handleConfirm, confirmCB }) {

    const initialState = {};

    value?.split(", ").forEach(i => {
        initialState[i] = true;
    });

    const [state, setState] = useState(initialState);
    const [items, setItems] = useState();
    const [showAlert, setShowAlert] = useState(false)
    const data = Object.entries(state).filter(([key, value]) => value).map(([key]) => key);

    const handleSubmit = async () => {
        if (data.length < 2 || data.length > 6) {
            setShowAlert(true);
            return;
        }
        const hashTags = data.join(", ").replace(/^[\s,]+|[\s,]+$/g, '');

        const sendData = {
            offerID,
            hashTags,
        }

        handleConfirm();
        sendDataHandler(sendData)
        .then(res => {
            if (res.ok) return res.json();
            else throw new Error(res.statusText);
        })
        .then(res => res && Alerts.showSuccessAlert(res.message))
        .then(() => confirmCB(hashTags))
        .catch(err => Alerts.showErrorAlert(err.message))
    }

    const sendDataHandler = (data) => {
        return fetch(loadURL, {
            method: Backend.backendMethods.patch,
            headers: Backend.generateHeader(),
            body: JSON.stringify(data),
        }).then(async res => {
            if (res.status === Backend.backendResponseCodes.unauthorizedCode) {

                Backend.logOut();
            }
            return res;
        });
    };

    const handleChange = (evt) => {
        const { name, checked } = evt.target;
        setState({
            ...state,
            [name]: checked,
        });
    };

    const getDataHandler = () => {
        return fetch(getDataURL, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader(),
        }).then(async res => {
            if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
                Backend.logOut();
            }
            return res;
        });
    };

    useEffect(() => {
        getDataHandler()
            .then(res => {
                if (res.ok) return res.json();
                else throw new Error(res.statusText);
            })
            .then(res => setItems(res.hashTags))
            .catch(err => Alerts.showErrorAlert(err.message));

    }, []);

    useEffect(() => {
        setInterval(() => {
            setShowAlert(false);
        }, 5000);
    }, [showAlert])


    return (
        <div>
            <div className="text-left">
                <p
                    style={{ maxHeight: showAlert ? 35: 0 }}
                    className="error-message hastags-error-display">{content.hashtags_alert_message}</p>
                {
                    <FormControl component="fieldset" variant="standard" required={data.length>=6 || data.length<2}>
                        <FormGroup sx={{ display: 'block' }}>
                        {
                            items?.length>0 ?
                            items.map(each => (
                                <FormControlLabel
                                    sx={{ width: 'fit-content' }}
                                    key={each}
                                    control={
                                        <Checkbox
                                            onChange={handleChange} 
                                            checked={state[each] || false}
                                            name={each} 
                                        /> 
                                    }
                                    label={each}
                                />
                            )) :
                            null
                        }
                        </FormGroup>
                    </FormControl>
                }
            </div>

            <hr className='give-bonus-swal-hr margin-top-10 margin-bottom-10' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{content.select}</button>
        </div>
    )
}