import React, { useState } from 'react';
import Backend from 'Backend.js';
import Alerts from 'utils/Alerts';
import { MenuItem, Select, Button } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Date from 'utils/Date.js';

const yesterday = dayjs().subtract(1, 'day');

const content = require('content.json');
const backendURLs = Backend.backendURLs;
const getValidationsCsvURL = backendURLs.getValidationsCsvURL;
const createValidationsURL = backendURLs.createValidationsURL;
const validationPresignedURL = backendURLs.validationPresignedURL;

export default function ValidateModalForm({ providerFilter }) {
    const defaultProvider = providerFilter[0];
    const [provider, setProvider] = useState(defaultProvider);
    const [error, setError] = useState(false);
    const [csvFile, setCsvFile] = useState(null);
    const [bannerFileToSend, setBannerFileToSend] = useState(null);

    const startDateDefaultValue = (defaultProvider.LatestEndDate) ?
        dayjs(defaultProvider.LatestEndDate).add(1, 'day') :
        null;
    const [minStartDateValue, setMinStartDateValue] = useState(startDateDefaultValue);
    const [startDateValue, setStartDateValue] = useState(null);
    const [endDateValue, setEndDateValue] = useState(null);

    const downloadURL = (url, name) => {
        const link = document.createElement("a");
        link.download = name;
        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const thenCallback = async res => {
        if (
            res.status === Backend.backendResponseCodes.internalServerErrorCode ||
            res.status === Backend.backendResponseCodes.serviceUnavailableCode
        ) {
            setError(res.statusText || !res.ok);
        } else if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } 
        else {
            let result = await res.json();
            return result;
        }
    };

    const validateRequest = (param) => {
        const isInvalidData = error || param || !startDateValue || !endDateValue;
        if (isInvalidData) return true;
    }

    const handleGetValidationsCsv = () => {
        if (validateRequest()) return;

        const { ProviderName, ProviderID } = provider;
        const { startDate, endDate } = Date.yearMMDDFormatter(startDateValue, endDateValue);

        const params = new URLSearchParams();
        params.append("providerID", ProviderID);
        params.append("providerName", ProviderName);
        params.append("startDate", startDate);
        params.append("endDate", endDate);

        fetch(getValidationsCsvURL + `?${params}`, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader(),
        })
            .then(thenCallback)
            .then(result => result?.fileLink && downloadURL(result.fileLink, "download.csv"))
            .catch(err => setError(err));
    }

    const handleGetValidationPresignedUrl = async() => {
        const { ProviderName, ProviderID } = provider;
        const { startDate, endDate } = Date.yearMMDDFormatter(startDateValue, endDateValue);

        const params = new URLSearchParams();
        params.append("providerID", ProviderID);
        params.append("providerName", ProviderName);
        params.append("startDate", startDate);
        params.append("endDate", endDate);

        return fetch(validationPresignedURL + `?${params}`, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader(),
        })
            .then(thenCallback)
    }

    const handleCreateValidations = async() => {
        const { ProviderName, ProviderID } = provider;
        const { startDate, endDate } = Date.yearMMDDFormatter(startDateValue, endDateValue);

        const sendData = {
            providerID: ProviderID,
            providerName: ProviderName,
            startDate,
            endDate,
        };
        const body = JSON.stringify(sendData);

        return fetch(createValidationsURL, {
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body,
        })
            .then(thenCallback)
            .catch(err => {
                setError(err);
            });
    }

    const handleUploadCsv = async (url) => {
        const file = bannerFileToSend;
        const requestOptions = {
            method: "PUT",
            body: file,
            headers: {
                "Content-Type": "text/csv",
            }
        };

        return fetch(url, requestOptions)
            .then(async res => {
                if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
                    Backend.logOut();
                }
            })
            .catch(error => console.log('error', error));
    }

    const handleSubmit = (evt) => {
        if (validateRequest(!bannerFileToSend)) return;

        const submitElement = evt.target;
        submitElement.disabled = true;

        handleGetValidationPresignedUrl()
        .then(res => handleUploadCsv(res?.url))
        .then(() => handleCreateValidations())
        .then(res => Alerts.showSuccessAlert(res?.message))
        .catch(err => Alerts.showErrorAlert(err?.message));
    }

    const changeHandler = ({ target: { files } }) => {
        setCsvFile(files[0]);
        if (files.length === 0) return;

        const fr = new FileReader();
        fr.readAsArrayBuffer(files[0]);
        fr.onload = () => setBannerFileToSend(fr.result);
    }

    const handleChangeStartDate =(value) => {
        setStartDateValue(value);
    }

    const handleChangeEndDate =(value) => {
        setEndDateValue(value)
    }

    const providerChangeHandler = (newProvider) =>  {
        setProvider(newProvider);
        const dateValue = newProvider.LatestEndDate;

        const minStartDate = (dateValue) ? 
            dayjs(dateValue).add(1, 'day') :
            null;
        setMinStartDateValue(minStartDate);
        setStartDateValue(null);
    }

    return (
        <div className='text-left'>
            <div className="margin-bottom-10 padding-bottom-10">
                <Select
                    style={{ fontFamily: 'inherit' }}
                    size="small"
                    value={provider.ProviderName}
                >
                    {providerFilter.map(each => (
                        <MenuItem 
                            style={{ 
                                fontFamily: 'inherit' 
                            }}
                            key={each.ProviderID}
                            value={each.ProviderName} 
                            onClick={() => providerChangeHandler(each)}
                        >
                            { each.ProviderName }
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div className="margin-bottom-60">
                <p className='font bold margin-left-10 padding-bottom-10'>Date range:</p>
                <div className='flex gap-10'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker 
                                views={['year', 'month', 'day']}
                                label="Start"
                                format="DD.MM"
                                minDate={minStartDateValue}
                                maxDate={yesterday}
                                value={startDateValue}
                                onChange={handleChangeStartDate}
                                slotProps={{
                                    textField: {
                                        readOnly: true,
                                    },
                                }}
                                sx={{
                                    "& input": {
                                        padding: '14px',
                                    },
                                    "&": {
                                        fontFamily: 'inherit',
                                        width: 'fit-content',
                                    },
                                    "& *": {
                                        fontFamily: 'inherit'
                                    },
                                    maxWidth: '70px !important',
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker 
                                label="End" 
                                format="DD.MM"
                                minDate={startDateValue}
                                maxDate={yesterday}
                                disabled={!startDateValue}
                                value={endDateValue}
                                onChange={handleChangeEndDate}
                                slotProps={{
                                    textField: {
                                        readOnly: true,
                                    },
                                }}
                                sx={{
                                    "& input": {
                                        padding: '14px',
                                    },
                                    "&": {
                                        fontFamily: 'inherit',
                                        width: 'fit-content',
                                    },
                                    "& *": {
                                        fontFamily: 'inherit'
                                    },
                                    maxWidth: '70px !important',
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
            </div>
            <div className="flex items-start justify-around margin-bottom-60">
                <div className='margin-right-60'>
                    <Button 
                        sx={{
                            border: '1px solid rgba(27, 28, 30, 0.15)',
                            borderRadius: '8px',
                            background: 'white',
                            padding: '5px 25px',
                            "&": {
                                fontFamily: "inherit"
                            }
                        }}
                        className="font capitalize"
                        variant="raised" 
                        component="h2"
                        onClick={handleGetValidationsCsv}
                        startIcon={<FileDownloadIcon />}
                    >
                        { content.export }
                    </Button>
                </div>
                <div className="margin-bottom-20">
                    <form name="input-form" id="input-form" encType="multipart/form-data" className="relative">
                        <input
                            type="file"
                            accept=".csv"
                            id="upload-file"
                            onChange={changeHandler}
                            hidden
                        />
                        <label htmlFor="upload-file">
                            <Button 
                                sx={{
                                    border: '1px solid rgba(27, 28, 30, 0.15)',
                                    borderRadius: '8px',
                                    background: 'white',
                                    padding: '5px 25px',
                                    "&": {
                                        fontFamily: "inherit"
                                    }
                                }}
                                className="font capitalize"
                                variant="raised" 
                                component="h2"
                                startIcon={<FileUploadIcon />}
                            >
                                { content.import }
                            </Button>
                        </label> 
                        { csvFile && <label className="validate-modal-import-file-name">{ csvFile.name }</label> }
                    </form>
                </div>
            </div>
            <div className='text-right margin-bottom-60'>
                <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{ content.validate }</button>
            </div>
        </div>
    )
}
