import React, { useState } from 'react';
import { Box } from '@mui/system';
import { MenuItem, Select } from '@mui/material';
import Header from 'components/text/Header.js';
import Uploader from 'components/widgets/Uploader';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import Button from '@mui/material/Button';
import { apiDownloadResourceTemplate, apiUploadResourceTemplate } from '../services/ResourceService'
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Alerts from 'utils/Alerts.js';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';



const content = require('content.json');
const configuration = require('configuration.json');


const ModuleDropDown = configuration.modules;
let siteLanguages = configuration.languages;

let LanguagesUploadSelect = {};


siteLanguages.forEach(key => {
    LanguagesUploadSelect[key.code] = key.label
});

export default function UploadText() {

    const [module, setModule] = useState([])
    const [downloadLang, setDownloadLang] = useState(siteLanguages[0].code)
    const [isUploading, setIsUploading] = useState(false)
    const [errorObj, setErrorObj] = useState("");
    const [rerender, setRerender] = useState();
    const [ignoreValidation, setIgnoreValidation] = useState(false);
    const [allowAllKeys, setAllowAllKeys] = useState(false);
    const [includeTranslated, setIncludeTranslated] = useState(false);
    const [showIgnoreValidationBtn,  setShowIgnoreValidationBtn] = useState(true);

    const onUpload = async files => {
        
        setIsUploading(true)
        setShowIgnoreValidationBtn(true)
        setErrorObj("")
        const uploadLang = files[0].path
        const reader = new FileReader();

        reader.readAsText(files[0].raw, "UTF-8");
        reader.onload = async (event) => {
            let uploadFileObj;
            try {
                uploadFileObj = JSON.parse(event.target.result)
            } catch (error) {
                Alerts.showErrorAlert("JSON parsing Error")
                setErrorObj({"JSON parsing Error": error.message})
                setShowIgnoreValidationBtn(false)
                clearUlpoadComponent()
            }
            if (typeof (uploadFileObj) == 'object') {
                try {
                    const uploadRes = await apiUploadResourceTemplate(uploadFileObj, uploadLang, ignoreValidation);

                    if (uploadRes.success) {
                        Alerts.showSuccessAlert(uploadRes.message)
                        
                    } else {
                        Alerts.showErrorAlert(uploadRes.message)
                        if (uploadRes?.err) {
                            setErrorObj(uploadRes.err)
                        }
                    }
                } catch (error) {
                    Alerts.showErrorAlert("Uploading Error")
                    setErrorObj({"Server Error 500": error.message})
                    setShowIgnoreValidationBtn(false)
                }

                clearUlpoadComponent()
            }

            setIsUploading(false)
            setIgnoreValidation(false)
        }
    }

    const clearUlpoadComponent = () => {
        setRerender(Math.floor(Math.random() * 9999))
    }

    const downLoadFile = async () => {
        const res = await apiDownloadResourceTemplate(downloadLang, module, allowAllKeys, includeTranslated);

        if (!res.success) {
            if (res.message) {
                Alerts.showErrorAlert(res.message)
            }
            return;
        }

        var element = document.createElement('a');
        const date = new Date();
        element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(res.resources)));
        element.setAttribute('download', `${downloadLang}-${date.toLocaleDateString().replaceAll("/", "")}.json`);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);

    }

    const onModuleChange = (event) => {
        const value = event.target.value;
        setModule(
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const onUploadWithIssues = (event) => {
        setIgnoreValidation(prev => !prev)
    }


    const IssuesToFiz = ( {data} ) => {

        const printRecursive = (el) => {
            if(typeof el == 'string' ){
                return (
                    <div style={{ paddingLeft: "15px" }}>
                        ➜ {el}
                    </div>
                ) 
            }else if(typeof el == 'object'){
                return Object.keys(el).map((module, idx) => {
                    return (
                        <div key={idx} style={{ paddingLeft: "15px" }}>
                            {!Array.isArray(el) && <h3>{module}</h3>}
                            {printRecursive(el[module])}
                        </div>
                    )
                })
            }
        }

        return printRecursive(data)

    }

    return (
        <div className='upload-content'>
            <Header />
            <div className="content-container">
                <Box sx={{
                    display: 'flex', flexFlow: 'row', columnGap: 3
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                        gap: 2,
                        alignItems: 'left',
                        padding: 4,
                        background: 'white',
                        width: '100%',
                        textAlign: 'left'
                    }}>
                        <h2>Download Resource</h2>
                        <Select
                            size="small"
                            value={downloadLang}
                            onChange={e => setDownloadLang(e.target.value)}
                            sx={{ width: 300 }}
                        >
                            {siteLanguages.map(({ code, label, native_label }, index) => {
                                return (
                                    <MenuItem value={code} key={index}>
                                        {label} / {native_label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormControl sx={{ width: 300 }}>
                            <InputLabel size="small" id="multiple-module-label">Module</InputLabel>
                            <Select
                                labelId="multiple-module-label"
                                id="multiple-module"
                                size="small"
                                value={module}
                                onChange={onModuleChange}
                                multiple
                                renderValue={(selected) => selected.join(', ')}
                                input={<OutlinedInput sx={{ textAlign: "left" }} label="Module" />}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 250,
                                            width: 200,
                                        },
                                    }
                                }}
                            >
                                {Object.keys(ModuleDropDown).map((item, idx) => {
                                    return (
                                        <MenuItem value={item} key={idx}>
                                            <Checkbox checked={module.indexOf(item) > -1} />
                                            <ListItemText primary={ModuleDropDown[item]} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControlLabel control={<Checkbox onClick={() => setAllowAllKeys(prev => !prev)} checked={allowAllKeys} />} label="Include 'Not For Translate' Keys and Objects" />
                        {downloadLang != "en_US" && (
                            <FormControlLabel control={<Checkbox onClick={() => setIncludeTranslated(prev => !prev)} checked={includeTranslated} />} label="Include Translated Keys" />
                        )}

                        <Button sx={{ marginLeft: 'auto' }}
                            variant="contained"
                            onClick={downLoadFile}
                            startIcon={<ArrowDownwardOutlinedIcon />}
                            disabled={module.length > 0 ? false : true}
                        >{content.download}</Button>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                        gap: 2,
                        alignItems: 'left',
                        padding: 4,
                        background: 'white',
                        width: '100%',
                        textAlign: 'left'
                    }}>
                        <h2>Upload Resource</h2>
                        <p>*Important: first select the translated language you'r planning to upload</p>
                        <Uploader
                            key={rerender}
                            loader={isUploading}
                            onSubmit={onUpload}
                            pathSelectData={LanguagesUploadSelect}
                            accept="application/JSON"
                        />
                        {errorObj && (
                            <Paper sx={{ textAlign: "left", padding: "30px 20px" }} elevation={3} >
                                <h3 className="red" style={{ marginBottom: "20px" }}>Please fix the issues below.</h3>
                                <IssuesToFiz data={errorObj} />
                                {showIgnoreValidationBtn && (
                                    <FormGroup sx={{ marginTop: 5 }} >
                                    <FormControlLabel control={<Checkbox onClick={onUploadWithIssues} checked={ignoreValidation} />} label="I want to upload the resource with the issues" />
                                </FormGroup>
                                )}
                            </Paper>
                        )}
                    </Box>
                </Box>
            </div>
        </div>
    )
}