import React, { useRef } from 'react';
import Alerts from 'utils/Alerts.js';
import TextField from '@mui/material/TextField';
import { apiUpdateOffer } from '../../services/OfferService';

const content = require('content.json');


export default function EditClickthroughCapModalForm({offerId, value, handleCancel, handleConfirm, confirmCB}) {
    
    const clickthroughRef = useRef(null);

    const handleSubmit = async () => {

        const inputValue = clickthroughRef.current.value;

        if (!inputValue.trim()) return;

        if(inputValue < 0){
            return Alerts.showErrorAlert('Please provide a positive number only')
        }

        try {
            
            const res = await apiUpdateOffer(offerId, {TotalClickthroughCap: inputValue});
            handleConfirm()

            if(res.status == 'success'){
                Alerts.showSuccessAlert(`ClickThrough Limit updated`)
                confirmCB(inputValue)
            }else{
                Alerts.showErrorAlert(res.message)
            }

        } catch (err) {

            Alerts.showErrorAlert(err.message)
        }

    };



    return (
        <>
            <TextField
                required
                type="number"
                id="outlined-required"
                label="Clickthrough Limit"
                size="small"
                inputRef={clickthroughRef}
                defaultValue={value}
                className='margin-top-10'
                fullWidth
                InputProps={{ inputProps: { min: 0 } }}
            />

            <hr className='give-bonus-swal-hr margin-top-20 margin-bottom-10' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{content.edit}</button>
        </>
    );
}
