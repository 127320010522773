import React, { forwardRef } from 'react';
import { Box, TextField, FormControl} from '@mui/material';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';


const SearchField = forwardRef((props, ref) => {

    const {
        name,
        label,
        onChange
    } = props;


    const fieldName = name ?? "search"
    const fieldLabel = label ?? "Search"

    const handleChange = e => {
        if (onChange) {
            onChange(e)
        }
    }

    return (
        <Box>
            <FormControl>
                <TextField
                    sx={{ minWidth: '200px' }}
                    size="small"
                    className="grid-list_flex-form_input"
                    label={fieldLabel}
                    type="search"
                    inputRef={ref}
                    name={fieldName}
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{<SearchIcon style={{ opacity: 0.5 }} />}</InputAdornment>
                    }}
                />
            </FormControl>
        </Box>
    )

})

export default SearchField;