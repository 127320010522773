import React, { useEffect, useRef, useState } from 'react';
import Alerts from 'utils/Alerts.js';
import { Tooltip, Button, TextField, FormControl, Stack, MenuItem, Select, InputLabel, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { apiGetSecondConversions, apiSetSecondConversion } from '../../services/OfferService';
import SearchIcon from '@mui/icons-material/Search';
import DataTable from '../tables/DataTable';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import debounce from '../../utils/Debounce';




export default function SecondConversionModalForm({ uniqueOfferID, handleCancel, handleConfirm, confirmCB }) {

    const [offersData, setOffersData] = useState([]);
    const [offersLoading, setOffersLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [startRange, setStartRange] = useState(null);
    const [endRange, setEndRange] = useState(null);

    const searchByRef = useRef(null);
    const intervalFromRef = useRef(null);
    const intervalToRef = useRef(null);
    const searchRef = useRef(null);

    const isUpdateEnabled = Object.keys(selectedRow).length > 0 && startRange && endRange ? true : false;


    const offerColumns = [
        {
            id: 'UniqueOfferID',
            label: 'UniqueID',
            sortable: false,
            width: 150
        },
        {
            id: 'AppName',
            label: 'App Name',
            sortable: false,
            width: 150
        },
        {
            id: 'ProviderName',
            label: 'Provider',
            sortable: false,
            width: 150
        },
        {
            id: 'SupportedCountries',
            label: 'Country',
            sortable: false,
            parse: (val, row) => {
                return (
                    <Tooltip title={val} placement="bottom-start">
                        <span className='max1Lins'>{val}</span>
                    </Tooltip>
                )
            },
            width: 100
        },
        {
            id: 'SecondConversionID',
            label: '2nd conversion',
            sortable: false,
            width: 160,
            parse: (val, row) => {
                if (Boolean(val)) {
                    return (
                        <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                            <CheckCircleIcon color="success" />
                            <small className='text-underline-hover' onClick={e => onDelete2ndConversion(row.UniqueOfferID)}>Remove</small>
                        </Stack>
                    )
                }
            },
        },

    ];

    useEffect(() => {
        getSecondConversions();
    }, [])


    const onDelete2ndConversion = (targetUniqueOfferID) => {
        set2ndConversion({
            targetUniqueOfferID, 
            action: 0
        });
    }

    const onNew2ndConversion = () => {
        const targetUniqueOfferID = selectedRow.UniqueOfferID;
        set2ndConversion({
            targetUniqueOfferID,
            startRange,
            endRange
        });
    }

    const onUpdate2ndConversion = () => {
        const targetUniqueOfferID = selectedRow.UniqueOfferID;
        const secondConversionID = selectedRow.SecondConversionID;
        set2ndConversion({
            targetUniqueOfferID,
            secondConversionID,
            startRange,
            endRange
        });
    }

    const set2ndConversion = async (props) => {
        setOffersLoading(true);
        const res = await apiSetSecondConversion({ ...props, uniqueOfferID });
        
        if (res.status == "success") {
            onOffersSearch();
            setSelectedRow({});
        } else {
            Alerts.showErrorAlert(res?.message);
        }
        setOffersLoading(false);
    }


    const getSecondConversions = async (props) => {
        const search = props?.search || null;
        const searchBy = props?.searchBy || null;
        setOffersLoading(true);
        const res = await apiGetSecondConversions({ uniqueOfferID, search, searchBy });
        setOffersData(res.secondConversions);
        setOffersLoading(false);
    }


    const onOffersSearch = async () => {

        const searchBy = searchByRef.current.value;
        const search = searchRef.current.value;

        if (!searchBy) {
            return;
        }
        getSecondConversions({ search, searchBy });
    }

    const onOfferSelect = (row) => {
        if (row.length) {
            setSelectedRow(offersData[row[0]]);
            setStartRange(offersData[row[0]].StartRange || "");
            setEndRange(offersData[row[0]].EndRange || "");
        } else {
            setSelectedRow({});
            setStartRange("");
            setEndRange("");
        }
    }


    return (
        <>
            <Stack spacing={1} sx={{ m: 2, textAlign: "left" }}>
                <h5>Conditions</h5>
                <Stack direction="row" spacing={2}>
                    <FormControl sx={{ width: 250, textAlign: "left" }}>
                        <InputLabel size="small">Search By</InputLabel>
                        <Select
                            label="Search By"
                            inputRef={searchByRef}
                            size="small"
                        >
                            <MenuItem value="AppName">App Name</MenuItem>
                            <MenuItem value="UniqueOfferID">Unique Offer ID</MenuItem>
                            <MenuItem value="Provider">Provider</MenuItem>
                            <MenuItem value="Country">Country</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width: "100%" }} variant="outlined">
                        <InputLabel size="small" htmlFor="search-offer">Search</InputLabel>
                        <OutlinedInput
                            id="search-offer"
                            type="search"
                            size="small"
                            fullWidth
                            onChange={debounce(onOffersSearch)}
                            inputRef={searchRef}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={onOffersSearch}
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Search"
                        />
                    </FormControl>
                </Stack>
                <DataTable
                    tableData={offersData}
                    columns={offerColumns}
                    //filter={offerFilter}
                    loading={offersLoading}
                    onRowSelectChange={onOfferSelect}
                    maxHeight={300}
                    forceClearSelection={!Boolean(Object.keys(selectedRow).length)}
                    checkboxSelection
                    size="small"
                />

                <h5>Hour Interval</h5>
                <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                    <TextField
                        inputProps={{ type: 'number', min: 0 }}
                        size="small"
                        inputRef={intervalFromRef}
                        value={startRange}
                        onChange={e => setStartRange(e.target.value)}
                        sx={{ width: 80 }}
                    />
                    <span> - </span>
                    <TextField
                        inputProps={{ type: 'number', min: 0 }}
                        size="small"
                        inputRef={intervalToRef}
                        value={endRange}
                        onChange={e => setEndRange(e.target.value)}
                        sx={{ width: 80 }}
                    />
                    {selectedRow.SecondConversionID
                        ? <Button onClick={onUpdate2ndConversion} disabled={!isUpdateEnabled} variant="contained" style={{marginLeft:20}}>Update</Button>
                        : <Button onClick={onNew2ndConversion} disabled={!isUpdateEnabled} variant="contained" style={{marginLeft:20}}>Add to 2nd Conversion</Button>
                    }

                </Stack>

            </Stack>



        </>
    );
}
