import React, { useEffect, useState } from 'react';
import DataTable from 'components/tables/DataTable.js';
import { apiGetUserActivity } from 'services/UserActivityService.js';
import { useSearchParams } from 'react-router-dom';

const initialFilter = {
    "uniqueUserID": "",
    "amount": 20,
    "page": 1,
    "orderBy": "dateCreated",
    "order": "desc"
};

const columns = [
    {
        id: 'dateCreated',
        label: 'Date Created',
    },
    {
        id: 'treatID',
        label: 'ID',
    },
    {
        id: 'action',
        label: 'Action',
    },
    {
        id: 'context',
        label: 'Context',
    },
    {
        id: 'platform',
        label: 'Platform',
    },
    {
        id: 'context',
        label: 'Context',
    },
];

const ActivityLog = ({ user }) => {
    const [loader, setLoader] = useState(false);
    const [rows, setRows] = useState([]);
    const [total, setTotal] = useState(0);
    let [searchParams] = useSearchParams();
    const uniqueUserID = searchParams.get('uniqueUserID') || user.uniqueID;
    const [filter, setFilter] = useState({ ...initialFilter, uniqueUserID });

    const onPageChange = page => {
        setFilter(prev => ({ ...prev, page }));
    };

    const onFilterChange = filterObj => {
        setFilter(prev => ({ ...prev, ...filterObj, page: filterObj.page ? filterObj.page : 1 }));
    };

    const fetchData = async () => {
        setLoader(true);
        const data = await apiGetUserActivity(filter);
        setLoader(false);
        if (data) {
            setRows(data.actions);
            setTotal(data.total);
        }
    };

    useEffect(() => {
        fetchData();
    }, [filter]);

    return (
        <div className='user-table-container'>
            <div className="user-table-handlers">
                <p className="user-table-search-name">Activity Log</p>
            </div>
            <DataTable
                columns={columns}
                tableData={rows}
                onPageChange={onPageChange}
                onFilterChange={onFilterChange}
                filter={filter}
                loading={loader}
                total={total}
            />
        </div>
    )

}

export default ActivityLog;