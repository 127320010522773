import React, { useEffect, useRef, useState } from 'react';
import Alerts from 'utils/Alerts.js';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { apiGetReviewsTopics } from '../../services/ReviewsService';

const content = require('content.json');


export default function OfferReviewTopicModalForm({ reviewTopicID, handleCancel, handleConfirm, confirmCB }) {

    const [topicsData, setTopicsData] = useState([]);
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState("");

    const topicRef = useRef(null);

    useEffect(() => {
        fetchOfferTopics();
    }, [])

    const fetchOfferTopics = async () => {
        const res = await apiGetReviewsTopics();
        if (res?.topics) {
            setTopicsData(res.topics)
            const defaultTopic = res.topics.find(topic => topic.ID === reviewTopicID)
            setValue(defaultTopic)
        }
    }



    const handleSubmit = async () => {

        const inputValue = topicRef.current.value;

        const topic = topicsData.find(topic => topic.name === inputValue)

        if (Object.keys(topic).length == 0) {
            return Alerts.showErrorAlert("Error, something went wrong!")
        }

        handleConfirm()
        confirmCB(topic)
    };



    return (
        <>
            <Autocomplete
                disablePortal
                options={topicsData}
                fullWidth
                size='small'
                getOptionLabel={(option) => option.name ?? ""}
                renderInput={(params) => (
                    <TextField {...params} label="Topic" inputRef={topicRef} />
                )}
                value={value || null}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
            />

            <hr className='give-bonus-swal-hr margin-top-20 margin-bottom-10' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{content.edit}</button>
        </>
    );
}
