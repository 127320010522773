import React, { useState } from 'react';
import SelectModalForm from './Select_Modal_Form.js';
import Alerts from 'utils/Alerts.js';
import Backend from 'Backend.js';

const content = require('content.json');
const optionPlatForms = Object.values(content.fetch_offer_platforms);
const initialValue = optionPlatForms[0];

const backendURLs = Backend.backendURLs;
const loadURL = backendURLs.fetchOffersUrl;

export default function FetchOffersModalForm({ handleCancel, handleConfirm }) {
    const [dropDownValue, setDropDownValue] = useState(initialValue);

    const handleSubmit = async () => {
        const sendData = {
            platform: dropDownValue
        };

        handleConfirm();
        sendDataHandler(sendData)
            .then(res => {
                if (res.ok) return res.json();
                else throw new Error(res.statusText);
            })
            .then(res => res && Alerts.showSuccessAlert(res.message))
            .catch(err => Alerts.showErrorAlert(err.message));
    };

    const sendDataHandler = (data) => {
        return fetch(loadURL, {
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify(data),
        }).then(async res => {
            if (res.status === Backend.backendResponseCodes.unauthorizedCode) {

                Backend.logOut();
            }
        });
    };

    return (
        <div>
            <div>
                <SelectModalForm
                    isPlatformSelect={true}
                    value={dropDownValue}
                    items={optionPlatForms}
                    optionChangeHandler={(value) => setDropDownValue(value)}
                />

            </div>

            <hr className='give-bonus-swal-hr' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{content.fetch}</button>
        </div>
    );
}
