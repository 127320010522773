import React from 'react';
import Header from 'components/text/Header.js';

export default function Competitions() {
    return (        
        <div className='competitions-content'>
            <Header />
        </div>
    )
}
