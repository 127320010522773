import React, { useEffect, useState } from 'react';
import { Alert, TextField, FormControl, Stack, Paper, Button, Grid } from '@mui/material';
import { apiGetOfferStockAttributes, apiSaveOfferStockattribute, apiDeleteOfferStockattribute } from '../../services/OfferService';
import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';



const validations = [
    {
        key: "Heading",
        regex: "^.{1,40}$",
        errorMsg: "Can not be empty, 40 characters max",
    },
    {
        key: "AttributeMeaning",
        regex: "^.{1,15}$",
        errorMsg: "Can not be empty, 15 characters max",
    },
    {
        key: "ValuesList",
        regex: "^.+$",
        errorMsg: "Can not be empty, comma seperated",
    }
]

const StockOfferAttributes = (props) => {

    const { uniqueOfferID } = props;

    const [attributes, setAttributes] = useState([]);
    const [isSavingAttr, setIsSavingAttr] = useState({});
    const [responseMsg, setResponseMsg] = useState({});
    const [saveCombMessage, setSaveCombMessage] = useState({});


    useEffect(() => {
        getOfferStockAttributes()
    }, [])


    const getOfferStockAttributes = async () => {
        const offerStockAttributes = await apiGetOfferStockAttributes(uniqueOfferID)
        setAttributes(offerStockAttributes.stockAttributes)
    }


    const removeAttribute = async (idx) => {
        const attribute = attributes[idx];
        setAttributes(attributes.filter((o, i) => idx !== i));
        setResponseMsg(prev => ({ ...Object.keys(prev).filter((o, i) => idx === i) }));
        if (attribute.ID) {
            const res = await apiDeleteOfferStockattribute(uniqueOfferID, attribute.ID)
        }
    }

    const addAttribute = () => {
        const defaultAttribute = {
            Heading: "",
            AttributeMeaning: "",
            ValuesList: "",
        }
        const newAtt = [...attributes];
        newAtt.push(defaultAttribute)
        setAttributes(newAtt)
    }

    const onAttributeChange = (e, key, idx) => {
        let newAttr = [...attributes];
        newAttr[idx][key] = e.target.value;
        setAttributes(newAttr)
    }


    const saveAttribute = async (idx) => {
        setSaveCombMessage({})
        setResponseMsg({});
        const attribute = attributes[idx];

        if (isAttributeValid(idx)) {
            setIsSavingAttr(prev => ({ ...prev, [idx]: true }));

            const res = await apiSaveOfferStockattribute(uniqueOfferID, attribute)
            setIsSavingAttr(prev => ({ ...prev, [idx]: false }));
            setResponseMsg(prev => ({ ...prev, [idx]: res.message }));
            setSaveCombMessage(prev => ({ ...prev, [idx]: {msg: res.message, type: res?.status == "success" ? "success" : "error"}}))
            getOfferStockAttributes()
        }

    }

    const isAttributeValid = (idx) => {

        const attribute = attributes[idx];
        let isValid = true;
        let newAttributes = [...attributes];
        validations.map(validation => {
            const regex = new RegExp(validation.regex);
            if (regex.test(attribute[validation.key])) {
                if (newAttributes[idx]?.error?.[validation.key]) {
                    delete newAttributes[idx].error[validation.key]
                }
            } else {
                newAttributes[idx].error ??= {}
                newAttributes[idx].error[validation.key] = validation.errorMsg;
                isValid = false;
            }
        })
        setAttributes(newAttributes)
        return isValid;
    }

    return (
        <>
            <Stack spacing={5} sx={{ m: 2 }}>
                {attributes.map((att, idx) => {

                    let num = idx + 1;

                    return (
                        <Stack key={idx}>
                            <Paper elevation={3} sx={{ p: 2 }} >
                                <Stack spacing={3}>
                                    {saveCombMessage?.[idx]?.msg && (
                                        <Alert severity={saveCombMessage[idx].type}>{saveCombMessage[idx].msg}</Alert>
                                    )}
                                    <FormControl fullWidth >
                                        <TextField
                                            size="small"
                                            className="grid-list_flex-form_input"
                                            label={`Heading ${num}*`}
                                            value={att.Heading}
                                            onChange={(e) => onAttributeChange(e, "Heading", idx)}
                                            error={att?.error?.Heading ? true : false}
                                            helperText={att?.error?.Heading}
                                        />
                                    </FormControl>
                                    <Stack direction="row" spacing={2}>
                                        <FormControl fullWidth >
                                            <TextField
                                                size="small"
                                                className="grid-list_flex-form_input"
                                                label={`Attribute ${num}*`}
                                                value={att.AttributeMeaning}
                                                onChange={(e) => onAttributeChange(e, "AttributeMeaning", idx)}
                                                error={att?.error?.AttributeMeaning ? true : false}
                                                helperText={att?.error?.AttributeMeaning}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth >
                                            <TextField
                                                size="small"
                                                className="grid-list_flex-form_input"
                                                label={`Values*`}
                                                value={att.ValuesList}
                                                onChange={(e) => onAttributeChange(e, "ValuesList", idx)}
                                                error={att?.error?.ValuesList ? true : false}
                                                helperText={att?.error?.ValuesList}
                                            />
                                        </FormControl>
                                    </Stack>
                                </Stack>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{ mt: 1 }}
                                >
                                    <LoadingButton
                                        size="small"
                                        loading={isSavingAttr?.[idx]}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        onClick={() => saveAttribute(idx)}
                                        color="primary"
                                        variant="contained"
                                    >
                                        <span>Save</span>
                                    </LoadingButton>
                                    {responseMsg?.[idx] && (<small style={{ maxWidth: 500 }}>{responseMsg?.[idx]}</small>)}
                                    <Button
                                        size="small"
                                        onClick={() => removeAttribute(idx)}
                                        color="error"
                                    >
                                        Remove
                                    </Button>
                                </Grid>
                            </Paper>
                        </Stack>
                    )
                })}
            </Stack>
            {attributes.length < 2 && (
                <Button
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => addAttribute()}
                    color="primary"
                >
                    Add a new attribute
                </Button>
            )}
        </>
    );
}

export default StockOfferAttributes