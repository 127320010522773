import React from 'react';
import AddHotOfferModalSelect from 'components/forms/Add_Hot_Offer_Modal_Select.js';

const content = require('content.json');
const questions = content.hot_offer_questions;
const chooseLabelText = content.choose_label;

export default function AddHotOfferModalFormThirdSection({
    data,
    changeHandler, 
    disabled,
}) {
    const dataChangeHandler = key => newValue => changeHandler(key, newValue);
    return (
        <div
            style={disabled ? { pointerEvents: "none", opacity: "0.7" } : {}}
            className="hot-offer-modal-third">
            <h2 className="intro-survey-quiz-font heading purple text-left padding-top-10">{ "Status:" }</h2>
            <div className="text-right margin-bottom-20">
                <div className="flex gap-30 items-stretch margin-top-20">
                    <div className="flex-50">
                        <AddHotOfferModalSelect
                            defaultDropdownText={chooseLabelText}
                            value={questions[16].answers[data['16']]}
                            optionChangeHandler={dataChangeHandler("16")}
                            label={questions[16].question}
                            items={Object.entries(questions[16].answers)}
                        />
                    </div>
                    <div className="flex-50">
                        <AddHotOfferModalSelect
                            defaultDropdownText={chooseLabelText}
                            value={questions[17].answers[data['17']]}
                            optionChangeHandler={dataChangeHandler("17")}
                            label={questions[17].question}
                            items={Object.entries(questions[17].answers)}
                        />
                    </div>
                </div>

                <div className="flex gap-30 items-stretch margin-top-20">
                    <div className="flex-50">
                        <AddHotOfferModalSelect
                            defaultDropdownText={chooseLabelText}
                            value={questions[18].answers[data['18']]}
                            optionChangeHandler={dataChangeHandler("18")}
                            label={questions[18].question}
                            items={Object.entries(questions[18].answers)}
                        />
                    </div>
                    <div className="flex-50">
                        <AddHotOfferModalSelect
                            defaultDropdownText={chooseLabelText}
                            value={questions[19].answers[data['19']]}
                            optionChangeHandler={dataChangeHandler("19")}
                            label={questions[19].question}
                            items={Object.entries(questions[19].answers)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}