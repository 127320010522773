import React, { useState, useEffect } from 'react';
import { Stack, Button } from '@mui/material';
import { apiGetReviewsTopics, apiDeleteReviewTopic } from '../../services/ReviewsService';
import DataFilter from '../filters/DataFilter.js';
import SearchField from 'components/inputs/SearchField.js';
import Alerts from 'utils/Alerts.js';
import DataTable from '../tables/DataTable.js';


const initialFilter = {
    //"order": "desc",
    //"orderBy": "ProviderID",
    "page": 1,
    "pageSize": 20,
    //"filterValue": "",
    //"filterBy": ""
};


const ReviewTopics = () => {

    const [loader, setLoader] = useState(false);
    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState(initialFilter);
    const [total, setTotal] = useState(null);


    useEffect(() => {

        fetchReviewsTopics();

    }, [filter]);

    const fetchReviewsTopics = async () => {
        setLoader(true);
        const res = await apiGetReviewsTopics(filter);
        setLoader(false);
        if (res.topics) {
            setRows(res.topics);
            setTotal(res?.total);
        }
    };

    const columns = [
        {
            id: 'name',
            label: 'Topic',
            sortable: false,
            width: 300
        },
        {
            label: 'Action',
            sortable: false,
            parse: (val, row) => (
                <Button 
                    variant="contained" 
                    color='error' 
                    size='small' 
                    sx={{fontSize: 10}}
                    onClick={()=>deleteReviewTopic(row)}
                >
                    Delete
                </Button>
            ),
        }
    ];

   

    const deleteReviewTopic = async (row) => {
        setLoader(true);
        const res = await apiDeleteReviewTopic({topicID: row.ID})
        if (res?.message) {
            Alerts.showSuccessAlert(res.message)
        }
        fetchReviewsTopics();
    };


    const onFilterChange = filter => {
        setFilter(prev => ({ ...prev, ...filter }));
    };


    return (
        <>
            <Stack direction="row" spacing={2} alignItems="center">
                <DataFilter onChange={onFilterChange}>
                    <SearchField name="topicName" />
                </DataFilter>
            </Stack>
            <DataTable
                columns={columns}
                tableData={rows}
                onFilterChange={onFilterChange}
                filter={filter}
                loading={loader}
                total={total}
                maxHeight={'calc(100vh - 390px)'}
            />
        </>
    );

};

export default ReviewTopics;