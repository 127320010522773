import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'App.js';
import UserData from 'User_Data.js';
import Images from 'utils/Images.js';
import Formatting from 'utils/Formatting.js';
import { TextField } from '@mui/material';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const content = require('content.json');
const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

const Header = ({ isSearchVisible }) => {
    const { authorized } = useContext(AuthContext);
    const admin = UserData.getItem(userDataKeys.admin_name);
    const [adminName, setAdminName] = useState(admin);

    const customEventListener = event => {
        const { detail: name } = event;
        setAdminName(name);
    };

    useEffect(() => {
        window.addEventListener('adminNameChange', customEventListener);

        return () => {
            window.removeEventListener('adminNameChange', customEventListener);
        }
    }, []);

    return (
        <div className={`header flex padding padding-left-40 ${authorized ? 'justify-start' : ''}`}>
            {authorized ?
                <>
                    <div className="header-welcome-container">
                        <h2 className={`heading-h2 ${!authorized ? "bold" : ''}`}>
                            {Formatting.format(content.header_user, ["(username)"], [adminName])}
                        </h2>
                        <p className='header-desc text-left'>{ content.header_description } </p>
                    </div> 
                    {
                        isSearchVisible ?
                        (
                            <div className='header-search-container text-left'>
                                <TextField
                                    sx={{
                                        "& *": {
                                            fontFamily: 'inherit',
                                            textAlign: "left",
                                        },
                                    }}
                                    size="small"
                                    label="Search"
                                    type="search"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">{<SearchIcon style={{ opacity: 0.5 }} />}</InputAdornment>
                                    }}
                                />
                            </div>
                        ) :
                        null
                    }
                </> :
                <img src={Images.imageURL('logo-white.svg')} alt="Logo" />
            }
        </div>
    )
}

export default Header