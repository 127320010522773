import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import Backend from 'Backend.js';
import CellRow from 'components/gridList/Cell_Row.js';
import GridList from 'components/gridList/Grid_List.js';
import Header from 'components/text/Header.js';
import ActionButton from 'components/buttons/Action_Button.js';
import MultiOfferStepsModal from 'components/modals/Multi_Offer_Steps_Modal.js';
import Alerts from 'utils/Alerts.js';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const configuration = require('configuration.json');
const content = require('content.json');
const offerStatuses = configuration.offer_statuses;
const offerStatusIds = configuration.offer_status_ids;
const backendURLs = Backend.backendURLs;
const listOffersURL = backendURLs.listOffersURL;
const actionOffersURL = backendURLs.actionOffersUrl;
const offerUpdURL = backendURLs.offerUpdateUrl;

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: '#ffffff',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    fontFamily: 'Poppins',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
};


const columns = [
  {
    headerName: 'Action',
    field: 'OffersAction',
    filterable: false
  },
  {
    headerName: 'Status',
    field: 'Status',
    filterable: true
  },
  {
    headerName: 'Offer #ID (provider)',
    field: 'UniqueOfferID',
    filterable: false
  },
  {
    headerName: 'Provider',
    field: 'Provider',
    filterable: false
  },
  {
    headerName: 'App name',
    field: 'AppName',
    filterable: true
  },
  {
    headerName: 'System Payout',
    field: 'SystemPayout',
    filterable: true
  },
  {
    headerName: 'Main Title',
    field: 'MainTitle',
    filterable: true
  },
  {
    headerName: 'Description',
    field: 'Description',
    filterable: false
  },
  {
    headerName: 'Icon',
    field: 'Icon',
    filterable: false,
    width: 80
  },
  {
    headerName: 'Countries',
    field: 'Countries',
    filterable: true
  },
  {
    headerName: 'Platform type',
    field: 'PlatformType',
    filterable: false
  },
  {
    headerName: 'ClickThrough URL',
    field: 'Link',
    filterable: false
  },
  {
    headerName: 'Coins',
    field: 'Coins',
    filterable: true
  },
  {
    headerName: 'Coins referral',
    field: 'CoinsReferral',
    filterable: true
  },
  {
    headerName: 'Coins 2nd gen',
    field: 'Coins2ndGenReferral',
    filterable: true
  },
  {
    headerName: 'Coins 3rd gen',
    field: 'Coins3rdGenReferral',
    filterable: true
  },
  {
    headerName: 'Percentage',
    field: 'Percentage',
    filterable: true
  },
  {
    headerName: 'Percentage referral',
    field: 'PercentageReferral',
    filterable: true
  },
  {
    headerName: 'Percentage 2nd gen',
    field: 'Percentage2ndGenReferral',
    filterable: true
  },
  {
    headerName: 'Percentage 3rd gen',
    field: 'Percentage3rdGenReferral',
    filterable: true
  },
  {
    headerName: 'Offer type',
    field: 'OfferType',
    filterable: true
  },
  {
    headerName: 'Home page Category',
    field: 'CategoryHPID',
    filterable: false
  },
  {
    headerName: 'Stock Offer',
    field: 'IsStockOffer',
    filterable: false
  },
  {
    headerName: 'Stock Settings',
    field: 'StockSettings',
    filterable: false
  },
  {
    headerName: 'Best Offer Position',
    field: 'Position',
    filterable: true
  },
  {
    headerName: 'Show on Homepage',
    field: 'IsShowingOnHP',
    filterable: false
  },
  {
    headerName: '2nd conversion offer',
    field: 'Is2ndConversionOffer',
    width: 140
  },
  {
    headerName: 'Clickthrough Amount',
    field: 'ClickthroughsAmount',
    filterable: true
  },
  {
    headerName: 'Clickthrough Limit',
    field: 'TotalClickthroughCap',
    filterable: true
  },
  {
    headerName: 'Completions Cap',
    field: 'CompletionsCap',
    filterable: true
  },
  {
    headerName: 'Completions Cap Duration',
    field: 'CompletionsCapDuration',
    filterable: true
  },
  {
    headerName: 'Completions Count',
    field: 'CompletionsCount',
    filterable: true
  },
  {
    headerName: 'Cooking Required',
    field: 'CookingRequired',
    filterable: false,
    width: 60
  },
  {
    headerName: 'Disable submit a report',
    field: 'DisableSubmitReport',
    filterable: false,
    width: 60
  },
  {
    headerName: 'Review allowed',
    field: 'ReviewAllowed',
    filterable: false,
    width: 60
  },
  {
    headerName: 'Review required',
    field: 'ReviewRequired',
    filterable: false,
    width: 60
  },
  {
    headerName: 'Review Topic',
    field: 'ReviewTopicName',
    filterable: false,
    width: 60
  },
  {
    headerName: 'Difficulty Level',
    field: 'Level',
    filterable: true
  },
  {
    headerName: 'Offer theme',
    field: 'OfferTheme',
    filterable: true
  },
  {
    headerName: 'Category',
    field: 'Category',
    filterable: true
  },
  {
    headerName: 'Cashback Category',
    field: 'CashbackCategory',
    filterable: true
  },
  {
    headerName: 'X2 Offer',
    field: 'X2Offer',
    filterable: false,
    width: 60
  },
  {
    headerName: 'Gives competition coins',
    field: 'GivesCompetitionCoins',
    filterable: false,
    width: 60
  },
  {
    headerName: 'Gives Use Coins',
    field: 'GivesUseCoins',
    filterable: false,
    width: 60
  },
  {
    headerName: 'ConversionRate',
    field: 'ConversionRate',
    filterable: true
  },
  {
    headerName: 'Guides',
    field: 'Guides',
    filterable: false
  },
  {
    headerName: 'Video Guideline',
    field: 'GuidesVideoURL',
    filterable: false
  },
  {
    headerName: 'Offer #ID',
    field: 'ID',
    filterable: true
  }
];



const initialFilter = {
    filterBy: '',
    filterQuery: '',
    sortBy: '',
    sortDirection: 'DESC',
    amount: 20,
    status: '',
    platform: '',
    country: '',
    bestOffer: ''
};

const platformFilter = {
    0: 'Platform',
    1: 'Android',
    2: 'iOS',
    3: 'Desktop',
    4: 'All mobile',
    5: 'All'
};

const statusFilter = {
    0: 'Status',
    1: 'Active',
    2: 'Inactive',
    3: 'Not available',
    4: 'Removed by provider',
    5: 'Awaiting admin approval',
    6: 'Unavailable due to caps'
};

const columnFilter = {
    1: 'AppName',
    2: 'Provider',
    3: 'Level',
    4: 'OfferType',
    5: 'OfferTheme',
    6: 'Description',
    7: 'Category',
    8: 'CashbackCategory',
    9: 'HashTags',
    10: 'MainTitle',
    11: 'UniqueOfferID',
};

const countriesArray = configuration.countries_array;
const countriesFilter = {};

countriesFilter[0] = 'Country';
for (let i = 0, len = countriesArray.length; i < len; i++) {
    countriesFilter[i + 1] = countriesArray[i];
}

const initialModalProps = {
    ID: '',
    Status: ''
};

const initialMultiModalProps = {
    ID: '',
    OfferSteps: null
}

export default function Offers() {
    const [page, setPage] = useState(1);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(20);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState(initialFilter);
    const [openModal, setOpenModal] = useState(false);
    const [openMultiModal, setOpenMultiModal] = useState(false)
    const [modalProps, setModalProps] = useState(initialModalProps);
    const [multiOfferProps, setMultiOfferProps] = useState(initialMultiModalProps)
    const [loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0)

    const strFilter = JSON.stringify(filter);

    const handleSortChange = ({ sortBy, sortDirection }) => {
        setFilter(prev => ({ ...prev, sortBy, sortDirection }));
        setPage(1);
    };

    const handleSearchChange = ({ period, filterBy, filterQuery, platform, status, country, bestOffer}) => {
        setFilter(prev => ({ ...prev, period, filterBy, filterQuery, platform, status, country, bestOffer }));
        setPage(1);
    };

    const handleScroll = () => {
        if (items && items.length < total - page * filter.amount) {
            setPage(prev => prev + 1);
        }
    };

    const changeOfferStatusCallback = async res => {
        if (
            res.status === Backend.backendResponseCodes.internalServerErrorCode ||
            res.status === Backend.backendResponseCodes.serviceUnavailableCode
        ) {
            setError(res.statusText || !res.ok);
        } else if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.badRequestCode) {
            let result = await res.json();
            Alerts.showErrorAlert(result.message);
        }
        else {
            await getOffers();
        }
    };

    const getOffersCallback = async res => {
        if (
            res.status === Backend.backendResponseCodes.internalServerErrorCode ||
            res.status === Backend.backendResponseCodes.serviceUnavailableCode
        ) {
            setError(res.statusText || !res.ok);
        } else if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.badRequestCode) {
            let result = await res.json();
            Alerts.showErrorAlert(content[result.message]);
        }
        else {
            let result = await res.json();
            setItems(result.offers);
            setTotalRows(result.total);
        }
        setLoading(false)
    };

    const getOffers = () => {
        setLoading(true)
        fetch(listOffersURL + `${`?${new URLSearchParams({ ...filter, page })}`}`, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader(),
        })
            .then(getOffersCallback)
            .catch(err => {
                setError(err);
            });
    };

    const handleChangeOfferStatus = async () => {
        await fetch(actionOffersURL, {
            method: Backend.backendMethods.patch,
            headers: Backend.generateHeader(),
            body: JSON.stringify({
                id: modalProps.ID,
                action: modalProps.StatusID === offerStatusIds.active ? offerStatusIds.inactive : offerStatusIds.active,
                hotOfferId: modalProps.hotOfferId
            }),
        })
            .then(changeOfferStatusCallback)
            .catch(err => {
                setError(err);
            });
        setOpenModal(false);
    };

    const handleOpenModal = ({ ID, StatusID, hotOfferId, isSystemOffer }) => {
        setModalProps({ ID, StatusID, hotOfferId, isSystemOffer });
        setOpenModal(true);
    };

    const handleMultiModal = ({ ID, OfferSteps }) => {
        setMultiOfferProps({ ID, OfferSteps });
        setOpenMultiModal(true);
    }

    const handleOfferChange = async (id, field, value) => {
        const body = {
            offerID: id,
            [field]: value
        };

        try{
          const response = await fetch(offerUpdURL, {
              method: Backend.backendMethods.patch,
              headers: Backend.generateHeader(),
              body: JSON.stringify(body),
          })
          const res = await response.json();
          if(res?.status != "success"){
            Alerts.showErrorAlert(res?.message || "update system error 500")
          }
          return res;
        }catch(err){
          setError(err)
        }
            
    };

    const onNewOffer = () => {
      Alerts.showNewOfferAlert((e)=> getOffers())
    }



    useEffect(() => {
        getOffers();
    }, [page, strFilter]);

    return (
        <div className="homepage-content">
            <Header />
            <div className="content-container">
                {loading && (
                  <Box className="loader">
                    <CircularProgress />
                  </Box>
                )}
                <GridList
                    height={92}
                    columns={columns}
                    items={items}
                    CellRowComponent={CellRow}
                    searchBy="Offers"
                    error={error}
                    handleScroll={handleScroll}
                    handleSortChange={handleSortChange}
                    handleSearchChange={handleSearchChange}
                    handleOpenModal={handleOpenModal}
                    handleOfferChange={handleOfferChange}
                    platformFilter={platformFilter}
                    statusFilter={statusFilter}
                    countriesFilter={countriesFilter}
                    columnFilter={columnFilter}
                    updateItems={getOffers}
                    setItems={setItems}
                    handleMultiModal={handleMultiModal}
                    onNewOffer={onNewOffer}
                />
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 1.5
                }}>
                    <div style={{flex: 1, textAlign:"left"}}>
                        Showing results {(page*total)-total + 1}-{page*total} from <b>{totalRows}</b> total results
                    </div>
                    <Stack spacing={2}>
                        <Pagination 
                          count={Math.ceil(totalRows/total)} 
                          defaultPage={1}  
                          siblingCount={2} 
                          color="secondary"
                          onChange={(e,page) => setPage(page)}
                        />
                    </Stack>
                    <div style={{flex: 1}}></div>
                </Box>
            </div>
            {openModal && (
                <Modal
                    open={openModal}
                    onClose={() => {
                        setOpenModal(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <div>
                            <h3 className="modal-heading-dark modal-heading">
                                {`${modalProps.isSystemOffer ? "Refresh" :
                                      [2,5,100].includes(modalProps.StatusID) ? "Activate" : "Deactivate"
                                    } 
                                `} offer
                            </h3>
                            <div className="withdrawals-modal-line">
                                <p>
                                    Are you sure you want to
                                    {`${modalProps.isSystemOffer ? "Refresh" :
                                      [2,5,100].includes(modalProps.StatusID) ? " Activate" : " Deactivate"
                                    } 
                                `} this offer?
                                </p>

                            </div>
                            <div className='modal-buttons-container'>
                                <ActionButton text='Cancel' onClick={() => { setOpenModal(false); }} />
                                <ActionButton
                                    type={modalProps.StatusID == 1 ? 'reject' : 'approve'}
                                    text={(
                                      modalProps.isSystemOffer ? "Refresh" :
                                      [2,5,100].includes(modalProps.StatusID) ? " Activate" : " Deactivate"
                                    )}
                                    className={modalProps.isSystemOffer ? "refresh" : ''}
                                    onClick={handleChangeOfferStatus}
                                />
                            </div>
                        </div>
                    </Box>
                </Modal>
            )}
            {openMultiModal && (
                <MultiOfferStepsModal
                    openMultiModal={openMultiModal}
                    setOpenMultiModal={setOpenMultiModal}
                    multiOfferProps={multiOfferProps}
                    handleOfferChange={handleOfferChange}
                />
            )}
        </div>
    );
}