import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box } from '@mui/system';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const CopyToCliboard = ({ showIcon, text, copyText, sx }) => {

    const [cliboardText, setCliboardText] = useState(copyText);
    const [elClass, setElClass] = useState("");

    const copyTextToCliboard = (e) => {
        navigator.clipboard.writeText(cliboardText)

        setElClass("copied")

        setTimeout(function () {
            setElClass("")
        }, 1000);
    }

    return (
        <Box className={`offers-copy-icon ${elClass}`} onClick={copyTextToCliboard}>
            {showIcon && (
                <>
                    <div className='copyToCliboardPrimary'>
                        <ContentCopyIcon color="disabled" sx={sx} />
                    </div>
                    <div className='copyToCliboardSuccess'>
                        <TaskAltIcon color="disabled" sx={sx} />
                    </div>
                </>
            )}
            {text}
        </Box>
    )
}

export default CopyToCliboard