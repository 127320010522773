import Backend from 'Backend.js';
import Alerts from 'utils/Alerts.js';

export const apiGetUserReferrals = async (filter) => {
    const queryString = new URLSearchParams(filter).toString();
    try {
        const res = await fetch(`${Backend.backendURLs.getUserReferralsURL}?${queryString}`, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader()
        });

        if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.OKCode) {
            return await res.json();
        } else {
            Alerts.showErrorAlert('Something went wrong');
        }

    } catch (error) {
        Alerts.showErrorAlert('Something went wrong');
    }
};