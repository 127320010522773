import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import OfferMultiStep from 'components/forms/Offer_Multi_Step.js';
import React, { useState } from 'react';
import ActionButton from 'components/buttons/Action_Button.js';

const content = require('content.json');

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: '#ffffff',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    fontFamily: 'Poppins',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
};

const MultiOfferStepsModal = ({ openMultiModal, setOpenMultiModal, multiOfferProps, handleOfferChange }) => {
    const [steps, setSteps] = useState(multiOfferProps['OfferSteps']);
    return (
        <Modal
            open={openMultiModal}
            onClose={() => {
                setOpenMultiModal(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <div>
                    <h3 className="modal-heading-dark modal-heading">
                        {content.modal_multi_event.header}
                    </h3>
                    <div className="withdrawals-modal-line">
                        <div>{content.modal_multi_event.level}</div>
                        <div>{content.modal_multi_event.level_desc}</div>
                        <div>{content.modal_multi_event.treatcoin_amout}</div>
                    </div>
                    <div className="withdrawals-modal-line modal-buttons-container multi-offer-container">
                        {steps && steps.map(step =>
                            <OfferMultiStep
                                key={step['stepNumber']}
                                step={step}
                                handlePropsChange={setSteps}
                            />)}

                    </div>
                    <div className='modal-buttons-container'>
                        <ActionButton text='Cancel' onClick={() => { setOpenMultiModal(false); }} />
                        <ActionButton
                            type={'edit'}
                            text={'Save'}
                            onClick={() => {
                                handleOfferChange(multiOfferProps['ID'], 'offerSteps', steps);
                                setOpenMultiModal(false);
                            }
                            }
                        />
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default MultiOfferStepsModal;