import React, { useCallback, useState } from 'react';
import Backend from 'Backend.js';
import Alerts from 'utils/Alerts.js';
import AddFreeOfferModalFormFirstSection from 'components/forms/Add_Free_Offer_Modal_Form_First_Section.js';
import AddFreeOfferModalFormASecondSection from 'components/forms/Add_Free_Offer_Modal_Form_Second_Section.js';

const content = require('content.json');

const backendURLs = Backend.backendURLs;
const loadURL = backendURLs.addFreeOfferUrl;

export default function AddFreeOfferModalForm({ 
    offerID,
    isPercentageOffer,
    disabled,
    infoInitial
}) {
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        minBalance: (infoInitial["MinBalanceToShow"] !== null && infoInitial["MinBalanceToShow"] !== undefined) 
                    ? infoInitial["MinBalanceToShow"] : 0.00,
        maxBalance: (infoInitial["MaxBalanceToShow"] !== null && infoInitial["MaxBalanceToShow"] !== undefined) 
                    ? infoInitial["MaxBalanceToShow"] : 0.00,
        clicks: (infoInitial["ClicksCap"] !== null && infoInitial["ClicksCap"] !== undefined) 
                ? infoInitial["ClicksCap"] : 0,
        available: (infoInitial["AvailableForReferrals"] !== null && infoInitial["AvailableForReferrals"] !== undefined) 
                   ? infoInitial["AvailableForReferrals"] : true,
        paid: (infoInitial["DisplayToPaidUsersOnly"] !== null && infoInitial["DisplayToPaidUsersOnly"] !== undefined) 
                ? infoInitial["DisplayToPaidUsersOnly"] : true,
        hours: (infoInitial["DisplayRecentActivityThreshold"] !== null && infoInitial["DisplayRecentActivityThreshold"] !== undefined) 
                ? infoInitial["DisplayRecentActivityThreshold"] : 0,
        users: (infoInitial["DisplayCap"] !== null && infoInitial["DisplayCap"] !== undefined)
                ? infoInitial["DisplayCap"] : 0
    });

    const numberWithDecimals = useCallback((value) => Number((+value).toFixed(2)), []);

    const isValidated = () => {
        return (
            +info.minBalance > 0 &&
            +info.maxBalance >= +info.minBalance &&
            +info.clicks >= 0 &&
            +info.hours >= 0 &&
            +info.users >= 0
        );
    }

    const handleSubmit = () => {
        if (!isValidated()) return;

        let minBalanceToShow = numberWithDecimals(info.minBalance);
        let maxBalanceToShow = numberWithDecimals(info.maxBalance);
        let clicksCap = info.clicks > 0 ? +info.clicks : null;
        let displayRecentActivityThreshold = info.hours > 0 ? +info.hours : null;
        let displayCap = info.users > 0 ? +info.users : null;

        const sendData = { 
            offerID,
            minBalanceToShow,
            maxBalanceToShow,
            clicksCap,
            availableForReferrals: +info.available,
            displayToPaidUsersOnly: +info.paid,
            displayCap,
            displayRecentActivityThreshold,
        };

        setLoading(true);
        sendDataHandler(sendData)
            .then(async res => {
                setLoading(false);

                const result = await res.json();
                if (res.ok) Alerts.showSuccessAlert(result.message);
                else Alerts.showErrorAlert(result.message);
            });
    };

    const sendDataHandler = (data) => {
        return fetch(loadURL, {
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify(data),
        })
    };

    const infoChangeHandler = (key, value) => {
        setInfo(prev => ({
            ...prev,
            [key]: value
        }))
    }

    return (
        <div
            style={disabled ? { pointerEvents: "none", opacity: "0.7" } : {}}
            className='text-right overflow-hidden add-free-offer-container'>
            <AddFreeOfferModalFormFirstSection 
                isPercentageOffer={isPercentageOffer}
                info={info}
                infoChangeHandler={infoChangeHandler}
            />
            <AddFreeOfferModalFormASecondSection
                info={info}
                infoChangeHandler={infoChangeHandler}
            />

            <button 
                disabled={loading}
                onClick={handleSubmit} 
                className='swal2-confirm swal2-styled give-bonus-modal-confirm-button capitalize'
            >
                { content.save_offer }
            </button>
        </div>
    );
}
