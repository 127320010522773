import React from 'react'

const ActionButton = ({ type, text, onClick, className, children }) => {
    return (
        <button onClick={onClick} className={`action-button ${type} ${className}`}>
            {text}
            {children}
        </button>
    )
}

export default ActionButton