import React, { useState, useEffect, useRef } from 'react';
import Alerts from 'utils/Alerts.js';
import Backend from 'Backend.js';
import { TextField, FormControl, Grid, Select, MenuItem, InputLabel, OutlinedInput } from '@mui/material';
import { apiAddNewOffer } from '../../services/OfferService.js';
import { apiGetProviders } from '../../services/ProviderService.js';
import InputAdornment from '@mui/material/InputAdornment';


const content = require('content.json');

const offerTypesSelect = content.offer_types_select.filter(type => [1, 2, 3].includes(type.id))
const platformTypesSelect = content.platform_types_select

export default function AddNewOfferForm({ handleCancel, handleConfirm, confirmCB }) {
    const [providers, setProviders] = useState([]);
    const [provider, setProvider] = useState("");
    const [offerType, setOfferType] = useState("");
    const [platformType, setPlatformType] = useState("");
    const [urlTemplate, setUrlTemplate] = useState("");
    const [identifyParam, setIdentifyParam] = useState("");

    const inputAppnameRef = useRef(null);
    const inputCoinsRef = useRef(null);
    const uniqueIDRef = useRef(null);


    useEffect(() => {

        apiGetProviders({ amount: 100, page: 1 }).then(res => {
            const manualProviders = res.result.filter(provider => provider.ProviderID > 1000)
            setProviders(manualProviders)
        })

    }, [])

    const handleSubmit = async () => {

        if (isNaN(provider) || 
            !inputAppnameRef.current.value.trim() || 
            isNaN(inputCoinsRef.current.value) || 
            isNaN(uniqueIDRef.current.value) || 
            uniqueIDRef.current.value.length > 21 ||
            isNaN(offerType) || 
            isNaN(platformType) ||
            urlTemplate.length < 8 ||
            identifyParam.length == 0) return;

        try {
            const res = await apiAddNewOffer({
                provider,
                appName: inputAppnameRef.current.value,
                coins: inputCoinsRef.current.value,
                uniqueID: uniqueIDRef.current.value,
                offerType,
                platformType,
                urlTemplate,
                identifyParam
            });

            handleConfirm()

            if (res?.status == 'success') {
                Alerts.showSuccessAlert(`New Offer was added successfully`)
                confirmCB()
            } else {
                Alerts.showErrorAlert(res.message)
            }

        } catch (err) {

            Alerts.showErrorAlert(err.message)
        }

    };

    const querySeperatorSign = urlTemplate.indexOf('?') > 0 ? '&' : '?';


    return (
        <>
            <Grid
                container
                direction="row"
                spacing={3}
            >
                <Grid item xs={12}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="provider-select-label">Provider *</InputLabel>
                        <Select
                            labelId="provider-select-label"
                            value={provider}
                            label="Provider"
                            size='small'
                            onChange={(e) => setProvider(e.target.value)}
                            sx={{ textAlign: "left" }}
                        >
                            {providers.map((provider, idx) => {
                                return <MenuItem key={idx} value={provider.ProviderID}>{provider.ProviderName}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        fullWidth
                        inputRef={inputAppnameRef}
                        id="appName"
                        label="App Name"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        fullWidth
                        inputRef={inputCoinsRef}
                        id="coins"
                        label="Coins"
                        type="number"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        fullWidth
                        inputRef={uniqueIDRef}
                        id="uniqueID"
                        label="Unique ID"
                        type="number"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="offerType-select-label">Offer Type *</InputLabel>
                        <Select
                            labelId="offerType-select-label"
                            value={offerType}
                            label="Offer Type"
                            size='small'
                            onChange={(e) => setOfferType(e.target.value)}
                            sx={{ textAlign: "left" }}
                        >
                            {offerTypesSelect.map((type, idx) => {
                                return <MenuItem key={idx} value={type.id}>{type.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="platformType-select-label">Platform Type *</InputLabel>
                        <Select
                            labelId="platformType-select-label"
                            value={platformType}
                            label="Platform Type"
                            size='small'
                            onChange={(e) => setPlatformType(e.target.value)}
                            sx={{ textAlign: "left" }}
                        >
                            {platformTypesSelect.map((type, idx) => {
                                return <MenuItem key={idx} value={type.id}>{type.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                    >
                        <Grid item xs={6}>
                            <TextField
                                required
                                fullWidth
                                value={urlTemplate}
                                onChange={(e)=>setUrlTemplate(e.target.value)}
                                label="URL template"
                                size="small"
                                placeholder='https://'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                required
                                fullWidth
                                value={identifyParam}
                                onChange={(e)=>setIdentifyParam(e.target.value)}
                                label="Identify param"
                                size="small"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{`=<user-id>`}</InputAdornment>,
                                    startAdornment: <InputAdornment position="start">{querySeperatorSign}</InputAdornment>,
                                  }}
                            />
                        </Grid>
                    </Grid>
                    <small style={{color:'#bdbdbd'}}>
                        {urlTemplate}
                        {urlTemplate && identifyParam && ( `${querySeperatorSign}${identifyParam}=<user-id>`)}
                    </small>
                </Grid>
            </Grid>


            <hr className='give-bonus-swal-hr margin-top-20 margin-bottom-10' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{content.add}</button>
        </>
    );
}
