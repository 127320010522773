import Backend from 'Backend';
import Alerts from 'utils/Alerts.js';

export const apiGetUserProfileInfo = async (uniqueUserID) => {
    try {
        const res = await fetch(`${Backend.backendURLs.getUserProfileInfoURL}?uniqueUserID=${uniqueUserID}`, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader()
        });

        if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.OKCode) {
            return await res.json();
        } else {
            Alerts.showErrorAlert('Something went wrong');
        }
    } catch (error) {
        Alerts.showErrorAlert('Something went wrong');
    }
};

export const apiHandleUserNotes = async (data) => {
    const { uniqueUserID, header, note, noteID } = data;
    try {
        const res = await fetch(`${Backend.backendURLs.handleUserNotesURL}`, {
            method: Backend.backendMethods.post ,
            headers: Backend.generateHeader(),
            body: JSON.stringify({ uniqueUserID, header, note, noteID }) 
        });

        if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.OKCode) {
            Alerts.showSuccessAlert('Success');
            return await res.json();
        } else {
            Alerts.showErrorAlert('Something went wrong');
        }
    } catch (error) {
        Alerts.showErrorAlert('Something went wrong');
    } 
}

export const apiUserGiveBonus = async (data) => {
    const { uniqueUserID, amount, add, amountType, reason, language } = data;
    try {
        const res = await fetch(`${Backend.backendURLs.userGiveBonusURL}`, {
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify({ uniqueUserID, amount: add ? +amount : -amount, status: amountType, reason, language })
        });

        if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.OKCode) {
            Alerts.showSuccessAlert('Success');
            return await res.json();
        } else if (res.status === Backend.backendResponseCodes.badRequestCode) {
            const { message } = await res.json();
            Alerts.showErrorAlert(message);
        } else {
            Alerts.showErrorAlert('Something went wrong');
        }
    } catch (error) {
        Alerts.showErrorAlert(error.message);
    }
};