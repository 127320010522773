import React, { useEffect, useState } from 'react';
import DataTable from 'components/tables/DataTable.js';
import DataFilter from 'components/filters/DataFilter.js';
import DropDown from 'components/inputs/DropDown.js';
import { apiGetUserPaymentHistory } from 'services/PaymentHistoryService.js';
import { useSearchParams } from 'react-router-dom';

const initialFilter = {
    "uniqueUserID": "",
    "page": 1,
    "paymentMethod": "",
    "order": 'asc',
    "oderBy": '',
};

const columns = [
    {
        id: 'dateCreated',
        label: 'Date Created',
    },
    {
        id: 'paymentMethod',
        label: 'Payment Method',
    },
    {
        id: 'context',
        label: 'Context',
    },
    {
        id: 'currency',
        label: 'Currency',
    },
    {
        id: 'amount',
        label: 'Amount',
    },
    {
        id: 'treatCoinsUsed',
        label: 'TreatCoins Used',
    },
    {
        id: 'adminName',
        label: 'Admin Name',
    },
    {
        id: 'APIStatusSuccessful',
        label: 'API Status Successful',
    },
    {
        id: 'withdrawalID',
        label: 'Withdrawal ID',
    },
    {
        id: 'withdrawalStatus',
        label: 'Withdrawal Status',
    },
];

const type1Options = [
    { label: '-', value: '' },
    { label: 'PIX', value: 4 },
    { label: 'Xoxoday ', value: 5 },
    { label: 'PST ', value: 6 },
];

const PaymentHistory = ({ user }) => {
    let [searchParams] = useSearchParams();
    const uniqueUserID = searchParams.get('uniqueUserID') || user.uniqueID;

    const [loader, setLoader] = useState(false);
    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState({ ...initialFilter, uniqueUserID });
    const [total, setTotal] = useState(0);


    const onPageChange = page => {
        setFilter(prev => ({ ...prev, page }));
    };

    const onFilterChange = filterObj => {
        setFilter(prev => ({
            ...prev, ...filterObj,
            page: filterObj.page ? filterObj.page : 1,
            paymentMethod: filterObj.paymentMethod ? filterObj.paymentMethod : ''
        }));
    };

    const fetchData = async () => {
        setLoader(true);
        const data = await apiGetUserPaymentHistory(filter);
        setLoader(false);
        if (data) {
            setRows(data.payments);
            setTotal(data.total);
        }
    };

    useEffect(() => {
        fetchData();
    }, [filter]);

    return (
        <div className='user-table-container'>
            <div className="user-table-handlers">
                <p className="user-table-search-name">Referrals</p>
                <div className='flex justify-between gap-5 '>
                    <DataFilter onChange={onFilterChange} submitLabel="Submit">
                        <DropDown name='paymentMethod' label='Method' data={type1Options} />
                    </DataFilter>
                </div>
            </div>
            <DataTable
                columns={columns}
                tableData={rows}
                onPageChange={onPageChange}
                onFilterChange={onFilterChange}
                filter={filter}
                loading={loader}
                total={total}
            />
        </div>
    )

}

export default PaymentHistory;