import UserData from 'User_Data.js';

const configuration = require('configuration.json');
const globalConf = require('global_conf.json');
const content = require('content.json');

const accessPoints = configuration.access_points;
const serverHeaders = configuration.server_headers;
const methods = configuration.methods;
const responseCodes = configuration.response_codes;
const userDataKeys = configuration.user_data_keys;

const websiteConnection = globalConf.website_connection;
const azureAuth = globalConf.azure_auth;

const host = websiteConnection.backend_host;

const signInPoint = accessPoints.sign_in;
const subscriptionSettingsPoint = accessPoints.subscription_settings;
const updateSubscriptionSettingsPoint = accessPoints.update_subscription_settings;
const countriesListPoint = accessPoints.countries_list;
const listUsers = accessPoints.list_users;
const login = accessPoints.login;
const usersAction = accessPoints.users_action;
const listWithdrawals = accessPoints.list_withdrawals;
const approveWithdraw = accessPoints.approve_withdrawal;
const getWithdrawProps = accessPoints.get_withdraw_props;
const rejectWithdraw = accessPoints.reject_withdrawal;
const activateOffer = accessPoints.activate_offer;
const deactivateOffer = accessPoints.deactivate_offer;
const addHotOfferPoint = accessPoints.add_hot_offer;
const addFreeOfferPoint = accessPoints.add_free_offer;
const sendHotOffer = accessPoints.send_hot_offer;
const fetchOffers = accessPoints.fetch_offers;
const offersAction = accessPoints.offers_action;
const offersUpdate = accessPoints.offer_update;
const offersGetPresignedUrl = accessPoints.offer_get_presigned_url;
const offerGetBannerPresignedURL = accessPoints.offer_banner_get_presigned_url;
const offerGetIconPresignedURL = accessPoints.offer_icon_get_presigned_url;
const uploadFilePresignedURL = accessPoints.upload_file_get_presigned_url;
const downloadResourceTemplateUrl = accessPoints.download_resource_template;
const uploadResourceTemplateUrl = accessPoints.upload_resource_template;
const offersGuides = accessPoints.offers_guides;
const offersPositions = accessPoints.offers_positions;
const offersUpdatePositions = accessPoints.offers_update_positions;
const addNewOffer = accessPoints.add_new_offer;
const updateOfferVideoGuideline = accessPoints.update_offer_video_guideline;

const listOffers = accessPoints.list_offers;
const listOffersDone = accessPoints.list_offers_done;
const getDescriptions = accessPoints.get_descriptions;
const getHashtags = accessPoints.get_hashtags;
const getCategories = accessPoints.get_categories;
const getProviders = accessPoints.get_providers;
const getCsvUrl = accessPoints.get_csv_url;
const getValidationsCsv = accessPoints.get_validations_csv;
const createValidations = accessPoints.create_validations;
const validationPresignedURL = accessPoints.validation_presigned_url;
const addNewProvider = accessPoints.offers_add_provider;
const updateUploadedFiles = accessPoints.update_uploaded_files;
const getUploadedFiles = accessPoints.get_uploaded_files;
const offerCompleteAdd = accessPoints.offer_complete_add;
const offerCompleteHistory = accessPoints.offer_complete_history;
const getOfferById = accessPoints.get_offer_by_id;
const userCoinsEarning = accessPoints.user_coins_earning;
const userValidateCoins = accessPoints.user_validate_coins;
const getUserProfileInfo = accessPoints.get_user_profile_info;
const getUserReferrals = accessPoints.get_user_referrals;
const getUserPayments = accessPoints.get_user_payments;
const getUserBonuses = accessPoints.get_user_bonuses;
const getUserActivity = accessPoints.get_user_activity;
const handleUserNotes = accessPoints.handle_user_notes;
const userGiveBonus = accessPoints.user_give_bonus;
const offerStockAttribute = accessPoints.offer_stock_attribute;
const getOfferStockCombinations = accessPoints.get_offer_stock_combinations;
const offerStockCombination = accessPoints.get_offer_stock_combination;
const syncOfferStockCombination = accessPoints.sync_offer_stock_combination;
const secondConversions = accessPoints.second_conversions;
const offerReviews = accessPoints.offer_reviews;
const offerReviewsTopics = accessPoints.offer_reviews_topics;

const getMethod = methods.get;
const postMethod = methods.post;
const patchMethod = methods.patch;
const deleteMethod = methods.delete;
const putMethod = methods.put;

const OKCode = responseCodes.OK;
const badRequestCode = responseCodes.bad_request;
const unauthorizedCode = responseCodes.unauthorized;
const forbiddenCode = responseCodes.forbidden;
const internalBackendErrorCode = responseCodes.internal_server_error;
const serviceUnavailableCode = responseCodes.service_unavailable;

const loadingMessage = content.loading_message;
const errorMessage = content.error_message;
const restrictedMessage = content.restricted_message;
const requestsExceededMessage = content.requests_exceeded_message;

const backendURLs = {
  signInURL: host + signInPoint,
  subscriptionSettingsURL: host + subscriptionSettingsPoint,
  updateSubscriptionSettingsURL: host + updateSubscriptionSettingsPoint,
  listCountriesUrl: host + countriesListPoint,
  listUsersUrl: host + listUsers,
  loginUrl: host + login,
  usersActionUrl: host + usersAction,
  listWithdrawalsUrl: host + listWithdrawals,
  approveWithdrawUrl: host + approveWithdraw,
  getWithdrawPropsUrl: host + getWithdrawProps,
  rejectWithdrawUrl: host + rejectWithdraw,
  getDescriptionURL: host + getDescriptions,
  getHashtagsURL: host + getHashtags,
  getCategoriesURL: host + getCategories,
  getProvidersURL: host + getProviders,
  getCsvURL: host + getCsvUrl,
  getValidationsCsvURL: host + getValidationsCsv,
  createValidationsURL: host + createValidations,
  validationPresignedURL: host + validationPresignedURL,
  listOffersURL: host + listOffers,
  listOffersDoneURL: host + listOffersDone,
  fetchOffersUrl: host + fetchOffers,
  addHotOfferUrl: host + addHotOfferPoint,
  addFreeOfferUrl: host + addFreeOfferPoint,
  sendHotOfferUrl: host + sendHotOffer,
  activateOfferUrl: host + activateOffer,
  deactivateOfferUrl: host + deactivateOffer,
  actionOffersUrl: host + offersAction,
  offerUpdateUrl: host + offersUpdate,
  setOffersGetPresignedUrl: host + offersGetPresignedUrl,
  setOffersGetBannerPresignedUrl: host + offerGetBannerPresignedURL,
  setOffersGetIconPresignedUrl: host + offerGetIconPresignedURL,
  getUploadFilePresignedUrl: host + uploadFilePresignedURL,
  downloadResourceTemplateUrl: host + downloadResourceTemplateUrl,
  uploadResourceTemplateUrl: host + uploadResourceTemplateUrl,
  offersGuidesUrl: host + offersGuides,
  offersPositionUrl: host + offersPositions,
  offersUpdatePositionUrl: host + offersUpdatePositions,
  addNewOfferUrl: host + addNewOffer,
  addNewProviderUrl: host + addNewProvider,
  updateUploadedFilesUrl: host + updateUploadedFiles,
  getUploadedFilesUrl: host + getUploadedFiles,
  offerCompleteAddUrl: host + offerCompleteAdd,
  offerCompleteHistoryUrl: host + offerCompleteHistory,
  getOfferByIdURL: host + getOfferById,
  updateOfferVideoGuidelineURL: host + updateOfferVideoGuideline,
  userCoinsEarningURL: host + userCoinsEarning,
  userValidateCoinsURL: host + userValidateCoins,
  getUserProfileInfoURL: host + getUserProfileInfo,
  getUserReferralsURL: host + getUserReferrals,
  getUserPaymentsURL: host + getUserPayments,
  getUserBonusesURL: host + getUserBonuses,
  getUserActivityURL: host + getUserActivity,
  handleUserNotesURL: host + handleUserNotes,
  userGiveBonusURL: host + userGiveBonus,
  offerStockAttributeURL: host + offerStockAttribute,
  getOfferStockCombinationsURL: host + getOfferStockCombinations,
  offerStockCombinationURL: host + offerStockCombination,
  syncOfferStockCombinationURL: host + syncOfferStockCombination,
  secondConversionsURL: host + secondConversions,
  offerReviewsURL: host + offerReviews,
  offerReviewsTopicsURL: host + offerReviewsTopics,
};

const backendMessages = {
  loadingMessage: loadingMessage,
  errorMessage: errorMessage,
  restrictedMessage: restrictedMessage,
  requestsExceededMessage: requestsExceededMessage
};

const backendResponseCodes = {
  OKCode: OKCode,
  badRequestCode: badRequestCode,
  unauthorizedCode: unauthorizedCode,
  forbiddenCode: forbiddenCode,
  internalBackendErrorCode: internalBackendErrorCode,
  serviceUnavailableCode: serviceUnavailableCode
};

const backendMethods = {
  get: getMethod,
  post: postMethod,
  patch: patchMethod,
  delete: deleteMethod,
  put: putMethod
};

class Backend {
  static get backendURLs() {
    return backendURLs;
  }

  static get backendMessages() {
    return backendMessages;
  }

  static get backendResponseCodes() {
    return backendResponseCodes;
  }

  static get backendMethods() {
    return backendMethods;
  }

  static generateHeader() {
    let items = { 'Content-Type': 'application/json' };
    if (UserData.getItem(userDataKeys.jwt_token) !== null) {
      items[serverHeaders.authorization] = UserData.getItem(userDataKeys.jwt_token);
    }
    return new Headers(items);
  }

  static generateAzureHeader(azureToken) {
    let items = { 'Content-Type': 'application/json' };
    items[serverHeaders.mode] = azureAuth.mode;
    items[serverHeaders.authorization_ad] = azureToken;
    return new Headers(items);
  }

  static logOut() {
    UserData.setItem(userDataKeys.logout_action, true);
    window.location.replace('/');
  }

  static updateOffer(requestBody) {
    fetch(backendURLs.offerUpdateUrl, {
      method: Backend.backendMethods.patch,
      headers: Backend.generateHeader(),
      body: JSON.stringify(requestBody)
    }).then(async res => {
      if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
      };

    })
      .catch(error => console.log('error', error));
  }

}

export default Backend;