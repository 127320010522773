import React from 'react';
import Alerts from 'utils/Alerts.js'

const content = require('content.json');
const offerActions = content.offer_actions;

const ButtonOffer = ({
    variant,
}) => {
    const handleClick = () => {
        if (variant===offerActions.activate) Alerts.showActivateOfferAlert();
        else Alerts.showDeactivateOfferAlert();
    }

    return (
        <div>
            <button onClick={handleClick} className={`btn-withdrawal ${variant.toLowerCase()}`}>
                { variant }
            </button>
        </div>
    )
}

export default ButtonOffer;