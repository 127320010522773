import React, { Children, useRef, cloneElement } from 'react';
import { Box, Button } from '@mui/material';


const DataFilter = props => {

    const {
        children, 
        onChange, 
        submitLabel
    } = props;

    const submitText = submitLabel ?? "Search"
    const myRefs = useRef([]);


    const ChildrenElements = Children.map(children, (child, idx) => {
        return cloneElement(child, {
            ref: (el) => (myRefs.current[idx] = el),
        });
    });
    

    const onFilterSubmit = e => {
        let filterObj = {}
        myRefs.current.map(item => {
            const name = item?.node?.name ?? item.name
            filterObj[name] = item.value
        })
        filterObj.page = 1;
        onChange(filterObj)
    }

    return (
        <>
            {ChildrenElements}
            <Box>
                <Button sx={{ minWidth: '100px' }} variant="contained" onClick={onFilterSubmit}>{submitText}</Button>
            </Box>
        </>
    )

}

export default DataFilter;