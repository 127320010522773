import Backend from 'Backend';
import Alerts from 'utils/Alerts.js';

export const apiGetWithdrawals = async (filter) => {

    const queryString = new URLSearchParams(filter).toString();

    const res = await fetch(`${Backend.backendURLs.listWithdrawalsUrl}?${queryString}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    } else if (res.status === Backend.backendResponseCodes.OKCode) {
        return await res.json();
    } else {
        Alerts.showErrorAlert('Something went wrong');
    }

}

export const apiRejectWithdrawal = async (props) => {

    const res = await fetch(Backend.backendURLs.rejectWithdrawUrl, {
        method: Backend.backendMethods.patch,
        headers: Backend.generateHeader(),
        body: JSON.stringify({
            withdrawalID: props.id, 
            action: 2 
        })
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}


export const apiApproveWithdrawal = async (props) => {

    const res = await fetch(Backend.backendURLs.approveWithdrawUrl, {
        method: Backend.backendMethods.patch,
        headers: Backend.generateHeader(),
        body: JSON.stringify({
            withdrawalID: props.id, 
            action: 3,
        })
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}

export const apiGetWithdrawProps = async (props) => {

    const queryString = new URLSearchParams(props).toString();

    const res = await fetch(`${Backend.backendURLs.getWithdrawPropsUrl}?${queryString}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}

