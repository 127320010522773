import React, { useState } from 'react';
import Textarea from '@mui/joy/Textarea';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ImageIcon from '@mui/icons-material/Image';
import Paper from '@mui/material/Paper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';

const configuration = require('configuration.json');
const globalConf = require('global_conf.json');


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const GuideSteps = (props) => {

    const { data, onChange, onDeleteStep } = props

    const onValueChange = (e, stepIdx) => {

        onChange(stepIdx, { value: e.target.value })
    }

    const onImageChange = async (e, stepIdx) => {

        const file = e.target.files?.[0]
        const newFileName = Math.random().toString(36).slice(2, 12) + "." + file.name.split('.').pop();
        const fr = new FileReader();

        const previewImg = URL.createObjectURL(file)

        fr.readAsArrayBuffer(file);
        fr.onload = () => {

            onChange(stepIdx, {
                file: fr.result,
                raw: file,
                newFileName: newFileName,
                preview: previewImg,
                value: `${globalConf.website_connection.user_host}/${configuration.S3Bucket.offer_guides_path}${newFileName}`
            })
        };
    }


    const ImagePreview = ({src}) => {
        return src && <img src={src} style={{ maxWidth: '100%', marginTop: 1 }} />
    }
    
   
    return (
        <>
            {data.map((step, idx) => (
                <Paper key={idx} elevation={3} sx={{ padding: 1, marginBottom: 2 }}>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }} >
                        <Box sx={{ display: 'flex', gap: 0.5, flex: 1, marginBottom: 1, alignItems: 'center' }}>
                            
                            {step.type == 1 && (
                                <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }} >
                                        <Button component="label" sx={{  }} size='small' variant="contained" startIcon={<ImageIcon />}>
                                            Add Image
                                            <VisuallyHiddenInput type="file" onChange={(e) => onImageChange(e, idx)} accept="image/*" />
                                        </Button>
                                        {step.raw?.name ? <small>{step.raw.name}</small> : ""}
                                    </Box>
                                    <ImagePreview src={(step?.preview || `${step.value}`)} />
                                </Box>
                            )}
                        </Box>
                        {step.type == 0 && (<Textarea
                            sx={{ width: '100%' }}
                            placeholder="Type in here…"
                            value={step.value}
                            onChange={(e) => onValueChange(e, idx)}
                            minRows={1}
                            maxRows={5}
                        />
                        )}
                        <IconButton onClick={() => onDeleteStep(idx)} aria-label="delete" size="small" sx={{ width: '40px', height: '40px' }} >
                            <DeleteForeverIcon fontSize="small" />
                        </IconButton>
                    </Box>

                </Paper >
            ))}
        </>
    )

}

export default GuideSteps;