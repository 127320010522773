import React, { useState } from 'react';
import Alerts from 'utils/Alerts.js';
import Backend from 'Backend.js';
import Uploader from '../../components/widgets/Uploader';
import { apiGetPresignedURL, apiUploadImage } from '../../services/S3BucketService';
import { apiUpdateOfferVideoGuideline } from '../../services/OfferService.js';
import {CircularProgress, Box} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';

const globalConf = require('global_conf.json');
const content = require('content.json');
const configuration = require('configuration.json');


export default function VideoGuidelineUploaderForm({ uniqueOfferID, video, image, handleCancel, handleConfirm, confirmCB }) {

    const [isUploading, setIsUploading] = useState(false);
    const [originVideo, setOriginVideo] = useState(video);
    const [originImage, setOriginImage] = useState(image);
    const [imageFile, setImageFile] = useState(null);
    const [videoFile, setVideoFile] = useState(null);

    const handleSubmit = async () => {
        
        setIsUploading(true)

        let errMsg = "";
        let uploadObj = {
            uniqueOfferID,
            video: videoFile,
            image: imageFile
        };
        let files = [];
        const path = configuration.S3Bucket.offer_video_guides_path;

        if(videoFile == null && !originVideo && imageFile != ""){
            return Alerts.showErrorAlert("Image can not be uploaded without a video");
        }

        if(videoFile == "" && (imageFile || originImage)){
            return Alerts.showErrorAlert("Image can not be uploaded without a video");
        }

        if (videoFile?.name) {
            files.push(videoFile);
        }

        if (imageFile?.name) {
            files.push(imageFile);
        }

        for (let i = 0; i < files.length; i++) {
            try {
                var file = files[i];
                let fileName = `${Date.now()}_${file.name.replaceAll(' ', '_')}`;

                const PresignedURL = await apiGetPresignedURL(`${Backend.backendURLs.getUploadFilePresignedUrl}?path=${path}&filename=${fileName}&type=${file.mimeType}`);
                if (PresignedURL.success && PresignedURL?.url != null) {
                    const uploadRes = await apiUploadImage(PresignedURL.url, file);
                    if (!uploadRes.ok) {
                        throw { message: uploadRes.statusText };
                    }
                } else {
                    throw { message: PresignedURL.message };
                }

                const host = globalConf.website_connection.user_host;

                uploadObj[file.type] = `${host}/${path}${fileName}`;

            } catch (error) {
                errMsg = `${errMsg} ${file.name} - ${(error?.message)} </br>`;
            }
        }

        if(!errMsg){
            const updateRes = await apiUpdateOfferVideoGuideline(uploadObj);

            handleConfirm();

            if(updateRes.code > 0){
                Alerts.showErrorAlert(updateRes.message);
            }else{
                Alerts.showSuccessAlert('Video Guideline updated');
                confirmCB();
            }
        }else{
            Alerts.showErrorAlert(errMsg);
        }

        setIsUploading(false);

    }

    const onVideoUploadChange = (file) => {
        setVideoFile({...file[0], type: 'video'});
        setOriginVideo("");
    }

    const onImageUploadChange = (file) => {
        setImageFile({...file[0], type: 'image'});
        setOriginImage("");
    }

    const onVideoDelete = () => {
        setVideoFile("");
        setOriginVideo("");
    }

    const onImageDelete = () => {
        setImageFile("");
        setOriginImage("");
    }


    return (
        <Box sx={{ position: 'relative'}}>
            {isUploading && (
                <Box className="loader-mask">
                    <CircularProgress className="loader" />
                </Box>
            )}
            
            <Box sx={{ textAlign: 'left', marginBottom: 3 }}>
                <div>Guideline Video: <small>(.mp4 format)</small></div>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Uploader
                        onChange={onVideoUploadChange}
                        removeSubmitButton
                        accept="video/mp4"
                    />
                    {(originVideo && !videoFile) && (
                        <video style={{maxWidth: 350, maxHeight: 100}} controls="controls" preload="metadata">
                            <source src={originVideo} type="video/mp4" />
                        </video>
                    )}
                    {originVideo  && (
                        <IconButton onClick={onVideoDelete} aria-label="delete" size="small" sx={{ width: '40px', height: '40px' }} >
                            <DeleteForeverIcon fontSize="small" />
                        </IconButton>
                    )}
                </Box>
            </Box>
            <Box sx={{ textAlign: 'left' }}>
                <div>Guideline Image: <small>(.png format)</small></div>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Uploader
                        onChange={onImageUploadChange}
                        removeSubmitButton
                        accept="image/png"
                    />
                    {(originImage && !imageFile) && (
                        <div><img style={{maxWidth: 350, maxHeight: 100}} src={originImage} /></div>
                    )}
                    {originImage && (
                        <IconButton onClick={onImageDelete} aria-label="delete" size="small" sx={{ width: '40px', height: '40px' }} >
                            <DeleteForeverIcon fontSize="small" />
                        </IconButton>
                    )}
                </Box>
            </Box>



            <hr className='give-bonus-swal-hr margin-top-20 margin-bottom-10' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{content.add}</button>
        </Box>
    );
}
