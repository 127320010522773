import React, { useEffect, useState } from 'react';
import UserDetails from 'drawers/UserDetails.js';
import Header from 'components/text/Header.js';


function User() {   

    return (
        <div className="user-page">
            <Header />
            <UserDetails />
        </div>
    );
}

export default User;