import React, { useState } from 'react';

const content = require('content.json');

export default function EditTicketModalForm({ handleCancel, handleConfirm }) {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState(false);

    const handleSubmit = () => {
        if (title && description) {
            handleConfirm();
        }
        else !error && setError(true);
    }

    return (
        <div>
            <div>
                <p className="text-left">{ content.title }</p>
                <input
                    id="give-bonus-amount"
                    type="text" 
                    maxLength={40}
                    className="give-bonus-modal-amount-input" 
                    value={title}
                    onChange={({ target: { value } }) => setTitle(value)}
                />
            </div>
            <div>
                <p className="text-left">{ content.description }</p>
                <textarea 
                    value={description}
                    onChange={({ target: { value } }) => setDescription(value)}
                    className='give-bonus-modal-reason-input'></textarea>
            </div>

            {
                error ?
                <>
                    {
                        !title ? 
                        <p className="text-left error-message margin-0">{content.add_title_error}</p> : 
                        null
                    }
                    {
                        !description ? 
                        <p className="text-left error-message margin-0">{content.add_description_error}</p> : 
                        null
                    }
                </> :
                null
            }
            <hr className='give-bonus-swal-hr' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{ content.cancel }</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{ content.edit }</button>
        </div>
    )
}
