class Date {
    static yearMMDDFormatter = (start, end) => {
        const dateFormatter = new Intl.DateTimeFormat("en-Ca", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        }).format;
        const startDate = dateFormatter(start?.["$d"]);
        const endDate = dateFormatter(end?.["$d"]);

        return { 
            startDate,
            endDate,
        }
    }
}

export default Date;