import React, { useState } from 'react';
import Alerts from 'utils/Alerts.js';
import Backend from 'Backend.js';
import SelectContainerModalAddDescriptionForm from './Select_Container_Modal_Add_Description_Form.js';

const content = require('content.json');

const backendURLs = Backend.backendURLs;
const loadURL = backendURLs.offerUpdateUrl;

export default function AddAppnameModalForm({ id, value, handleCancel, handleConfirm, confirmCB }) {
    const [appName, setAppName] = useState(value);

    const handleSubmit = () => {
        if (!appName.trim()) return;

        const sendData = { offerID: id, appName };
        sendDataHandler(sendData)
        .then(res => {
            if (res.ok) {
                handleConfirm();
                return res.json();
            }
            else throw new Error(res.statusText);
        })
        .then(res => res && Alerts.showSuccessAlert(res.message))
        .then(() => confirmCB(appName))
        .catch(err => Alerts.showErrorAlert(err.message))
    }

    const sendDataHandler = (data) => {
        return fetch(loadURL, {
            method: Backend.backendMethods.patch,
            headers: Backend.generateHeader(),
            body: JSON.stringify(data),
        })
    }

    return (
        <div>
            <SelectContainerModalAddDescriptionForm
                value={appName}
                placeholder={content.add_appname}
                changeDescriptionHandler={(value) => setAppName(value)}
            />

            <hr className='give-bonus-swal-hr margin-top-20 margin-bottom-10' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{ content.cancel }</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{ value ? content.edit : content.add }</button>
        </div>
    )
}
