import React, { useState, useEffect } from 'react';
import Alerts from 'utils/Alerts.js';
import Backend from 'Backend.js';

const content = require('content.json');

const backendURLs = Backend.backendURLs;
const setOffersGetIconPresignedUrl = backendURLs.setOffersGetIconPresignedUrl;

export default function OfferIconModalForm({ handleCancel, handleConfirm, offerID, updateItems }) {
    const [bannerFile, setBannerFile] = useState(null);
    const [bannerFileToSend, setBannerFileToSend] = useState(null);
    const [presignedURL, setPresignedURL] = useState(null)
    const [generatedFileName, setGeneratedFileName] = useState(null);

    const getPresignedURL = () => {
        fetch(setOffersGetIconPresignedUrl, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader(),
        })
            .then(async res => {
                let result = await res.json();
                if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
                    Backend.logOut();
                }
                setPresignedURL(result.url);
                setGeneratedFileName(result.fileName);
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        getPresignedURL();
    }, [])
    

    const handleSubmit = async () => {
        if (!bannerFile) return;
        handleConfirm();

        try {
            await uploadImageToBucket(presignedURL);
            await Backend.updateOffer({ iconURL: generatedFileName, offerID });
            await updateItems();
            await Alerts.showSuccessAlert(`Offer ${offerID} modified`);
        } catch (error) {
            Alerts.showErrorAlert(error?.message);
        }


    };

    const bannerChangeHandler = (evt) => {
        setBannerFile(evt.target.files?.[0]);
        const fr = new FileReader();
        fr.readAsArrayBuffer(evt.target.files?.[0]);
        fr.onload = () => setBannerFileToSend(fr.result);
    };

    const uploadImageToBucket = async (url) => {
        const requestOptions = {
            method: 'PUT',
            body: bannerFileToSend,
            headers: {
                "Content-Type": bannerFile.type,
                "Content-Length": bannerFileToSend.byteLength
            }
        };

        await fetch(url, requestOptions)
            .then(async res => {
                if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
                    Backend.logOut();
                }
            })
            .catch(error => console.log('error', error));
    };



    return (
        <div>
            <div>
                <div className="grid-list_basic-cell text-left">
                    <label className='cursor-pointer' htmlFor="banner">{bannerFile ? bannerFile.name : content.upload_file}</label>
                    <input
                        type='file'
                        id="banner"
                        onChange={bannerChangeHandler}
                        accept='image/png'
                        className='hidden'
                    />
                </div>

            </div>

            <hr className='give-bonus-swal-hr margin-top-10 margin-bottom-10' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{content.add}</button>
        </div>
    );
}
