import Backend from 'Backend';

export const apiUpdateMediaGallery = async (file) => {

    const res = await fetch(Backend.backendURLs.updateUploadedFilesUrl, {
        method: Backend.backendMethods.post,
        body: JSON.stringify({
            filepath: file
        }),
        headers: Backend.generateHeader(),
    })

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}

export const apiGetMediaGallery = async () => {

    const res = await fetch(Backend.backendURLs.getUploadedFilesUrl, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader(),
    })

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

}