import React, { useState } from 'react';
import SelectModalForm from './Select_Modal_Form.js';
import Alerts from 'utils/Alerts.js';
import Backend from 'Backend.js';

const content = require('content.json');
const optionThemes = content.offer_themes.map(each => ({ id: each.ID, name: each.Name }));
const initialValue = optionThemes[0].name;

const backendURLs = Backend.backendURLs;
const loadURL = backendURLs.offerUpdateUrl;

export default function OfferThemesModalForm({ offerID, value, handleCancel, handleConfirm, confirmCB }) {
    const [dropDownValue, setDropDownValue] = useState(value || initialValue);
    const [dropDownId, setDropDownId] = useState();

    const handleSubmit = async () => {
        if (dropDownValue === (value || initialValue)) return;

        const sendData = {
            offerID,
            offerThemeID: dropDownId
        };

        handleConfirm();
        sendDataHandler(sendData)
            .then(res => {
                if (res.ok) return res.json();
                else throw new Error(res.statusText);
            })
            .then(res => Alerts.showSuccessAlert(res.message))
            .then(() => confirmCB(dropDownValue))
            .catch(err => Alerts.showErrorAlert(err.message));
    };

    const sendDataHandler = (data) => {
        return fetch(loadURL, {
            method: Backend.backendMethods.patch,
            headers: Backend.generateHeader(),
            body: JSON.stringify(data),
        }).then(async res => {
            if (res.status === Backend.backendResponseCodes.unauthorizedCode) {

                Backend.logOut();
            }
            return res;
        });
    };

    const optionChangeHandler = (value, id) => {
        setDropDownValue(value);
        setDropDownId(id);
    }
 
    return (
        <div>
            <div>
                <SelectModalForm
                    value={dropDownValue}
                    items={optionThemes}
                    isSimpleDropdown={true}
                    optionChangeHandler={optionChangeHandler}
                />

            </div>

            <hr className='give-bonus-swal-hr margin-top-10 margin-bottom-10' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{content.select}</button>
        </div>
    );
}
