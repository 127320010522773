import React, { useState } from 'react';
import Images from 'utils/Images.js';

const content = require('content.json');

function SelectModalForm({ 
    value, 
    label,
    items=[], 
    isOfferType, 
    isSimpleDropdown,
    optionChangeHandler, 
    isPlatformSelect,
    isSlim,
    isAddHotOfferModal
}) {
    const [open, setOpen] = useState(false);
    const clickHandler = () => setOpen(prev => !prev);
    const changeHandler = (value, id) => {
        optionChangeHandler(value, id);
        setOpen(false);
    }

    return (
        <div className={`select-container ${isAddHotOfferModal ? "h-full justify-between flex-column" : ""}`}>
            { isPlatformSelect && <h2 className='text-left comment-ticket-popup-description'>{ content.choose_platform }</h2> }
            { label && <h2 className='text-left comment-ticket-popup-description margin-bottom-10'>{ label }</h2> }
            <div className={`select-container-static text-left ${isSlim ? "slim" : ""}`}>
                {
                    isOfferType ?
                    <p className="font bold inline-block label-text">{ value.name }</p> :
                    <p className={`font inline-block label-text ${!isAddHotOfferModal ? "bold" : ""}`}>{ value }</p>
                }
                <div 
                    className='select-option-icon' 
                    onClick={clickHandler} >
                    <img 
                        src={Images.imageURL('select-option-icon.svg')}
                        alt='Expand'
                        width='11'
                        height='11'
                        className={`${open ? 'expanded' : ''}`}
                    /> 
                </div>
                {
                    open && items.length>0 ?
                    <div className={`font ${!isAddHotOfferModal ? "bold" : ""}`}>
                        {
                            isOfferType || isSimpleDropdown ?
                            items.filter(each => each.name !== value.name).map(each => (
                                <p 
                                    className='cursor-pointer'
                                    key={each.id + '_' + each.userDailyCap !== null ? each.userDailyCap : 0} 
                                    onClick={() => !isSimpleDropdown ?
                                        changeHandler(each) :
                                        changeHandler(each.name, each.id)
                                    }
                                > 
                                    { each.name } 
                                </p>
                            )) :
                            items.filter(each => each!==value).map(each => (
                                <p 
                                    className='cursor-pointer'
                                    key={each} 
                                    onClick={() => changeHandler(each)}
                                > 
                                    { each } 
                                </p>
                            ))
                        }
                    </div> :
                    null
                }
            </div>
        </div>
    )
}

export default SelectModalForm;