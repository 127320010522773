import React from 'react';
import AddHotOfferModalSelect from 'components/forms/Add_Hot_Offer_Modal_Select.js';

const content = require('content.json');
const questions = content.hot_offer_questions;
const chooseLabelText = content.choose_label;

export default function AddHotOfferModalFormFourthSection({
    data,
    changeHandler,
    countries,
    selectedCountry,
    countryChangeHandler,
    disabled,
}) {
    const dataChangeHandler = key => newValue => changeHandler(key, newValue);
    const countryHandler = newValue => countryChangeHandler(newValue);

    return (
        <div
            style={disabled ? { pointerEvents: "none", opacity: "0.7" } : {}}
            className="hot-offer-modal-fourth">
            <h2 className="intro-survey-quiz-font heading purple text-left padding-top-10">{ "Final step:" }</h2>
            <div className="text-right margin-bottom-20">
                <div className="flex gap-30 items-stretch margin-top-20">
                    <div className="flex-50">
                        <AddHotOfferModalSelect
                            defaultDropdownText={chooseLabelText}
                            value={countries[selectedCountry]}
                            optionChangeHandler={countryHandler}
                            label={content.country}
                            items={Object.entries(countries)}
                        />
                    </div>
                    <div className="flex-50">
                        <AddHotOfferModalSelect
                            defaultDropdownText={chooseLabelText}
                            value={questions[20].answers[data['20']]}
                            optionChangeHandler={dataChangeHandler("20")}
                            label={questions[20].question}
                            items={Object.entries(questions[20].answers)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}