import React, { useState } from 'react';
import Images from 'utils/Images.js';

function MultipleSelectModalForm({ 
    defaultDropdownText,
    value: selectedValues, 
    label,
    items=[], 
    optionChangeHandler
}) {
    const [open, setOpen] = useState(false);
    const labelText = selectedValues.length > 0 ?
        items[selectedValues[0] - 1][1] :
        defaultDropdownText;

    const clickHandler = () => setOpen(prev => !prev);

    const changeHandler = (id) => {
        optionChangeHandler(id);
    }

    return (
        <div
            style={open ? { position: 'relative', zIndex: 10 } : {}}
            className={`select-container multiple h-full justify-between flex-column`}>
            <h2 className='text-left comment-ticket-popup-description margin-bottom-10'>{ label }</h2>
            <div
                style={open ? { position: 'absolute', top: 40, zIndex: 10, background: "#fff", width: 250 } : {}}
                className={`select-container-static multiple text-left slim`}>
                <p className={`font label-text`}>{ labelText }</p>
                <div 
                    className='select-option-icon' 
                    onClick={clickHandler} >
                    <img 
                        src={Images.imageURL('select-option-icon.svg')}
                        alt='Expand'
                        width='11'
                        height='11'
                        className={`${open ? 'expanded' : ''}`}
                    /> 
                </div>
                {
                    open && items.length>0 ?
                    <div className={`font`}>
                        {
                            items.map(([key, value]) => (
                                <p 
                                    className={`cursor-pointer expanded-dropdown  dropdown-option ${selectedValues.indexOf(key.toString()) >= 0 ? "font bold aqua" : ""}`}
                                    key={key} 
                                    onClick={() => changeHandler(key)}
                                > 
                                    { value } 
                                </p>
                            ))
                        }
                    </div> :
                    null
                }
            </div>
        </div>
    )
}

export default MultipleSelectModalForm;