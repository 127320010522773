import { createContext, useEffect, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Backend from 'Backend.js';
import UserData from 'User_Data.js';
import LoginButton from 'components/buttons/Login_Button.js';
import Main from 'Main.js';
import { loginRequest } from 'authConfig.js';
import jwt from 'jwt-decode';
import { ActionModalContextProvider } from './contexts/Action_Modal_Context';
import { UserContextProvider } from './contexts/UserContext.js';
import ConfirmActionModal from 'components/modals/Confirm_Action_Modal';

const AuthContext = createContext(null);
const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;
const backendURLs = Backend.backendURLs;
const loginURL = backendURLs.loginUrl;
let jwtToken = UserData.getItem(userDataKeys.jwt_token);
const globalConf = require('global_conf.json');
const azureAuth = globalConf.azure_auth;


function App() {
    const [authorized, setAuthorized] = useState(Boolean(jwtToken))
    const { instance, accounts, inProgress } = useMsal()
    const [accessToken, setAccessToken] = useState(null)

    const getJwtTokenFromServer = (azureToken) => {
        fetch(loginURL, {
            method: Backend.backendMethods.post,
            headers: Backend.generateAzureHeader(azureToken)
        })
        .then(async (res) => {
            if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
                Backend.logOut()
            } 
            const result = await res.json()
            const decoded = jwt(result.token);
            const adminName = decoded.adminName;

            dispatchEvent(new CustomEvent('adminNameChange', { detail: adminName }));

            UserData.setItem(
                userDataKeys.jwt_token,
                result.token
            )
            UserData.setItem(
                userDataKeys.admin_name,
                adminName
            )
            setAuthorized(UserData.getItem(userDataKeys.jwt_token))
        })
        .catch(e => { console.log(e); })
    }

    function getAzureTokenFromSessionStorage() {
        let azureToken = null;
        const sessionData = Object.entries(sessionStorage);
        sessionData.forEach(([key, value]) => {
            if (key.includes('login.windows.net-idtoken'))
                azureToken = JSON.parse(value)['secret']
        });
        return azureToken;
    }

    useEffect(() => {
            let azureToken = getAzureTokenFromSessionStorage();
            if (azureToken) {
                getJwtTokenFromServer(azureToken);
            }
    }, [inProgress])
        
    function RequestAzureAccessToken() {
        const request = {
            ...loginRequest,
            account: accounts[0],
        }
        instance
            .acquireTokenSilent(request)
            .then(response => {
                setAccessToken(response.accessToken)
            })
            .catch(e => {
                instance.acquireTokenPopup(request).then(response => {
                    setAccessToken(response.accessToken)
                })
            })
    }

    function logOut() {
        UserData.clear();
        const logoutRequest = {
            account: accounts[0],
            postLogoutRedirectUri: azureAuth.redirectUri,
        };
        instance.logoutRedirect(logoutRequest);
    }

    if (UserData.getItem(userDataKeys.logout_action)) {
        UserData.removeItem(userDataKeys.logout_action);
        logOut();
    }

    return (
        <UserContextProvider>
        <AuthContext.Provider
            value={{
                authorized,
                setAuthHandler: () => setAuthorized(true),
                logOutHandler: () => logOut(),
            }}
        >
            <ActionModalContextProvider>
            {authorized ?
                <AuthenticatedTemplate>
                    <Main />
                </AuthenticatedTemplate>
                : <></>}
            <UnauthenticatedTemplate>
                <div className="login-layout_azure">
                    <LoginButton text="Login" onClick={RequestAzureAccessToken} />
                </div>
                    </UnauthenticatedTemplate>
                    <ConfirmActionModal />
            </ActionModalContextProvider>
        </AuthContext.Provider>
        </UserContextProvider>
    )
}

export { AuthContext }
export default App
