import React, { useState } from 'react';
import Images from 'utils/Images.js';

function AddHotOfferModalSelect({ 
    defaultDropdownText,
    value, 
    label,
    items=[], 
    optionChangeHandler, 
}) {
    const [open, setOpen] = useState(false);
    const clickHandler = () => setOpen(prev => !prev);
    const changeHandler = (value, id) => {
        optionChangeHandler(value, id);
        setOpen(false);
    }

    return (
        <div className={`select-container h-full justify-between flex-column`}
            style={open ? { position: 'relative', zIndex: 10 } : {}}
        >
            { label && <h2 className='text-left comment-ticket-popup-description margin-bottom-10'>{ label }</h2> }
            <div
                style={open ? {
                    position: 'absolute',
                    top: 35,
                    zIndex: 10,
                    background: "#fff",
                    maxHeight: 200,
                    maxWidth: 220,
                    overflow: 'scroll',
                } : {}}
                className={`select-container-static text-left slim `}>
                <p className={`font inline-block label-text capitalize`}>{value || defaultDropdownText}</p>

                <div 
                    className='select-option-icon' 
                    onClick={clickHandler} >
                    <img 
                        src={Images.imageURL('select-option-icon.svg')}
                        alt='Expand'
                        width='11'
                        height='11'
                        className={`${open ? 'expanded' : ''}`}
                    /> 
                </div>
                {
                    open && items.length > 0 ?
                        <div className={`font`}>
                            {
                                items.map(([key, value]) => (
                                    <p
                                        className={`cursor-pointer dropdown-option`}
                                        key={key}
                                        onClick={() => changeHandler(key)}
                                    >
                                        {value}
                                    </p>
                                ))
                            }
                        </div> :
                        null
                }
            </div>
        </div>
    )
}

export default AddHotOfferModalSelect;