import React from 'react';
import Header from 'components/text/Header.js';
import UsersTable from 'components/tables/Users_Table.js';

export default function Users() {

    return (
        <div className='homepage-content'>
            <Header />
            <UsersTable />
        </div>
    );
}
